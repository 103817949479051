* {
  transition: box-shadow 0.3s ease-in-out;
}

html {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

body {
  padding: 0 !important;
}

p {
  margin: 0;
}

.background-primary {
  background: #261bc9;
}

.background-test {
  background: #261bc9;
}

.btn-fill-primary.MAINNET {
  border: #261bc9 solid 2px;
  background-color: #261bc9;
  color: #ffffff;
}
.btn-fill-primary.TESTNET {
  border: #000000 solid 2px;
  background-color: #000000;
  color: #ffffff;
}
.btn-fill-primary:disabled {
  border: none;
  color: rgba(0, 0, 0, 0.87);
  background-color: #eeeef0;
}

.btn-fill-gray {
  border: none;
  background-color: #eeeef0;
  color: rgba(0, 0, 0, 0.87);
}

.btn-fill-white {
  border: none;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}
.btn-fill-white.MAINNET:hover {
  color: #ffffff;
  background-color: #261bc9;
}
.btn-fill-white.TESTNET:hover {
  color: #ffffff;
  background-color: #000000;
}

.btn-grid-primary.MAINNET {
  border: #261bc9 solid 2px;
  background-color: #ffffff;
  color: #261bc9;
}
.btn-grid-primary.MAINNET:hover {
  background-color: #261bc9;
  color: #ffffff;
}
.btn-grid-primary.TESTNET {
  border: #ffffff solid 2px;
  background-color: transparent;
  color: #ffffff;
}
.btn-grid-primary.TESTNET:hover {
  background-color: #ffffff;
  color: #000000;
}

.btn-img {
  padding: 0 !important;
  border: none;
  box-shadow: none;
  background-color: transparent !important;
}

.center-vertical {
  margin-top: auto;
  margin-bottom: auto;
}

.container {
  margin: 0 auto;
  max-width: 1152px;
  height: 100%;
}

.bor-box {
  border: 1px solid #d9d9d9;
}

.errHint {
  display: block !important;
  line-height: 1.5;
  margin: 4px 0 0 0;
  color: #fc0f1b;
}
.errHint-hide {
  display: none;
}

/* no svg */
.error-icon svg {
  height: 24px;
  width: 24px;
  padding: 2px;
}
.error-icon svg path {
  color: #fc0f1b;
}

.flex {
  display: -ms-flexbox;
  display: flex;
}

.flex-center-horizontal {
  align-items: center;
}

.flex-center-vertical {
  justify-content: center;
}

.flex-end-vertical {
  justify-content: flex-end;
}
.flex-align-self-center {
  align-self: center;
}

.align-bottom-next-to-input {
  line-height: 16px;
  align-self: flex-end;
}

.flex-column {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-full {
  flex: 100;
}

.flex-half {
  flex: 0 1 50%;
}

.w-auto {
  width: auto;
}

.w-15per {
  width: 15%;
}

.w-180 {
  width: 180px;
}

.w-25 {
  width: 25px;
}

.text_btn {
  flex: 100;
}

.text-bold {
  font-weight: 500;
}

.text-heavy {
  font-weight: 600;
}

.text-container {
  background-color: #eeeef0;
  padding: 16px;
  border-radius: 4px;
}

.text-exlarge {
  font-size: 20px;
}

.text-large {
  font-size: 16px;
}

.text-small {
  font-size: 12px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px;
}

.mt-16 {
  margin-top: 16px;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-40 {
  margin-left: 40px;
}

.mr-8 {
  margin-right: 8px;
}

.mg-rl-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mg-tb-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mg-rl-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pd-rl-24 {
  padding-right: 24px;
  padding-left: 24px;
}

.pd-tb-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.sign {
  width: 5%;
  text-align: center;
  align-self: flex-end;
}

/* total */

.layout {
  width: 100%;
  height: 100%;
  background-color: #efeff1;
}

.layout > div {
  min-height: 100vh;
}

.ant-btn-loading {
  align-items: center;
}

.ant-btn-loading span i {
  display: none;
}

span.errInput input {
  border-color: #fc0f1b;
}
span.errInput input:focus {
  border-color: #261bc9;
}

input.errInput {
  border-color: #fc0f1b;
}
input.errInput:focus {
  border-color: #261bc9;
}

.ant-form-item-control {
  line-height: 32px;
}

.ant-form {
  color: rgba(0, 0, 0, 1);
}

/* 제목 텍스트 강조 */
.topic.text-exlarge.text-bold {
  font-weight: 600 !important;
}

/* 제안자 주소 텍스트 스타일 변경 */
.ballotInfoDiv .addr.text-small {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

/* Status 텍스트 강조 */
.status.text-small {
  font-weight: 600 !important;
}

/* 제안 내용 텍스트 스타일 변경 */
.voteDiv .description {
  line-height: 1.4 !important;
  color: rgba(0, 0, 0, 1) !important;
  margin-bottom: 14px !important;
}

/* 우측 보팅 기간 텍스트 스타일 변경 */
.voteDiv .duration {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-left: 0px !important;
}

.voteDiv .duration div div {
  height: 24px !important;
  justify-content: right !important;
}

.voteDiv .duration div div span:first-child {
  margin-right: 8px !important;
}

.voteDiv .duration div div span {
  text-align: right !important;
}

/* 반응형 고려, 보팅기간 정보를 하단으로 보내기 */
.voteDiv .textContent {
  flex-wrap: wrap !important;
}

/* Active, Proposals, Finalized 세 섹션 사이에 구분선 추가 (해당 섹션 사이에 새로운 div를 만드는 것으로 가정하여 스타일 추가) */
.vote-sectionline {
  margin: 0px 16px 0 16px !important;
  border-bottom: 1px solid #c7c7c7 !important;
}
