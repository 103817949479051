/* Break Point */
$breakpointXsm: 360px;
$breakpointSm: 1120px;
$breakpointMd: 1440px;
$breakpointLg: 2560px;

/* Color */
$gray10: #000000;
$gray20: #1a1a1a;
$gray30: #2e2e2e;
$gray40: #404040;
$gray50: #464646;
$gray60: #858585;
$gray70: #a7a7a7;
$gray80: #d1d1d1;
$gray90: #f2f2f2;
$gray100: #fff;
$purple: #a484ff;
$purple2: #8458ff;
$purple3: #66529e;
$skyblue: #8db1e8;
$red: #fa5454;
$bgColor: #f1f2f9;
$primary: #5e5ff5;
$positive: #21d78a;
$critical: #eb524f;
$deepBlue: #5e5ff5;

/* font */
// $fontEN: 'Roboto', sans-serif;
$fontKR: "Noto Sans KR", sans-serif;
$fontBase: 14px;

$rootPath: "/assets/images/";
$publicPath: "";

$vh: var(--vh, 1vh);
