@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

@mixin close($width, $thick, $color, $radius: 0) {
  $sqrt2: 1.41;
  display: inline-block;
  position: relative;
  z-index: 1;
  width: $width;
  height: $width;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    width: $width * $sqrt2;
    height: $thick;
    background: $color;
    border-radius: $radius;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.wonders-vote-board {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
  margin-top: 32px;

  @include md {
    margin-top: 24px;
    gap: 2px;
    row-gap: 8px;
  }
  @include sm {
    margin-top: 24px;
    gap: 1px;
  }
}

.vote-board {
  width: calc((100% - (4px * 19)) / 20);

  @include md {
    width: calc((100% - (2px * 9)) / 10);
  }
  @include sm {
    width: calc((100% - (1px * 4)) / 5);
  }
  .img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    background-color: $gray40;
    font-size: 20px;
    line-height: 1.2;
    color: $gray60;
    font-weight: 600;

    @include md {
      height: 36px;
      font-size: 16px;
    }
    @include sm {
      height: 40px;
      font-size: 16px;
    }

    img {
      width: 100%;
      height: auto;

      @include md {
        width: auto;
        height: 20px;
      }
      @include sm {
        width: auto;
        height: 24px;
      }
    }
  }

  .vote-info {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #303030;

    @include md {
      height: 36px;
    }

    @include sm {
      display: none;
    }
  }

  // &.default {
  //   .icon {
  //     display: inline-flex;
  //     justify-content: center;
  //     width: 100%;
  //     img {
  //       width: 100%;
  //       height: auto;
  //       @include md {
  //         width: auto;
  //         height: 20px;
  //       }
  //     }
  //   }
  // }

  &.yes {
    .img-wrap {
      background-color: #28623f;
    }
    .vote-info {
      background-color: rgba($positive, 0.15);
    }
    .icon {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      border: 2px solid $positive;

      @include md {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.no {
    .img-wrap {
      background-color: #6f2929;
    }
    .vote-info {
      background-color: rgba($red, 0.15);
    }
    .icon {
      @include close(24px, 2px, $red, 0);

      @include md {
        @include close(16px, 2px, $red, 0);
      }
    }
  }
  &.abstain {
    .img-wrap {
      background-color: #8c7c42;
    }
    .vote-info {
      background-color: rgba(#d9cc88, 0.15);
    }
    .icon {
      width: 32px;
      height: 2px;
      background-color: #d9cc88;

      @include md {
        width: 22px;
      }
    }
  }
}

.vote-legend {
  display: flex; // safari pc 에서 범례 나오지 않아 추가
  margin-top: 16px;
  ul {
    display: flex;
    width: 100%;
    gap: 20px;
  }

  li {
    display: flex;
    align-items: center;
    gap: 6px;
    color: $gray100;
    font-size: 12px;
    line-height: 1.5;

    &::before {
      @include content;

      width: 12px;
      height: 12px;
    }

    &.yes {
      &::before {
        background-color: #28623f;
      }
    }

    &.no {
      &::before {
        background-color: #6f2929;
      }
    }

    &.abstain {
      &::before {
        background-color: #8c7c42;
      }
    }

    &.none {
      &::before {
        background-color: $gray40;
      }
    }
  }
}
