// core 관련 색상, mixin (font) 정의
@import "../../assets/scss/core";

.platform-menu {
  position: relative;

  // dark mode
  &.dark {
    @include theme-map($theme-dark);
  }

  // light mode
  &.light {
    @include theme-map($theme-light);
  }

  .btn {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    @include core-transition-default(background-color);

    svg {
      width: 28px;
      height: 28px;
      path {
        fill: theme-color($theme-base-color);

        @include core-transition-default(fill);
      }
    }

    &:hover {
      background-color: theme-rgba($theme-base-color, $state-hover);
    }

    &.active,
    &:active {
      background-color: theme-rgba($theme-base-color, $state-pressed);
    }
  }

  .platform-contents-box {
    position: absolute;
    z-index: 10;
    top: calc(100% + 4px);
    right: 0;
    overflow: auto;
    width: 342px;
    height: 406px;
    padding-right: 8px;
    border: 1px solid theme-rgba($theme-base-color, $line-subtler);
    border-radius: 12px;
    background-color: theme-color($core-bg-subtler);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 11%), 0 0 5px 0 rgb(0 0 0 / 5%);
    opacity: 0;
    overscroll-behavior: contain;

    @include core-scroll;
    @include core-transition-default(opacity);

    &.enter {
      opacity: 1;
    }

    [class*="platform-inner-section"] {
      width: calc(100% + 8px);
    }
  }
}
