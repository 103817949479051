@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

// filter style
.filter-container {
  position: relative;

  .filter-tools {
    display: flex;
    align-items: center;

    .button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
      border: 1px solid $gray50;
      margin-right: 12px;
      color: $gray100;
      gap: 4px;

      &:focus-visible {
        border-color: $gray100;
      }
    }

    .result {
      @include body-2;
      color: $gray60;

      .value {
        color: $gray100;
      }
    }
  }
}

.filter-area {
  position: absolute;
  z-index: 111;
  top: calc(100% + 8px);
  left: 0;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px;
  border: 1px solid $gray50;
  background-color: $gray30;
  width: 264px;
  max-height: 360px;

  @include sm {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    padding: 24px 20px 0;
    transform: translate(0%, 100%);
    max-height: calc(($vh * 100) - 96px);
    border: none;

    @include transition-default(transform);
  }

  &.active {
    display: flex;

    @include sm {
      transform: translate(0%, 0%);
    }
  }
}

.bg {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 70%);

  @include sm {
    display: block;
  }
}

.btn-apply {
  display: block;
  padding: 12px 16px;
  margin-top: 16px;
  border-top: 1px solid $gray50;
  color: $gray100;
  margin: 0 -20px;

  @include body-2;
}

.filter-input-wrap {
  border: 1px solid $gray50;
  padding: 8px;

  &:hover {
    border-color: $gray60;
  }

  &:focus-within {
    border-color: $gray80;
  }

  input {
    width: 100%;
    border: none;
    background-color: transparent;
    color: $gray100;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $gray60;
    }
  }
}
