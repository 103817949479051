@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

.voting-status-dark {
  &.approved {
    @include statusBgDark($positive);
  }
  &.rejected {
    @include statusBgDark($critical);
  }
  &.ready {
    @include statusBgDark($gray70);
  }
  &.active {
    @include statusBgDark($purple);
  }
  &.inprogress {
    @include statusBgDark($purple);
  }
}
