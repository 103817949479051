@import "./variables";
@import "./mixin";

.voting-status-dark {
  &.approved {
    @include statusBgDark($positive);
  }
  &.rejected {
    @include statusBgDark($critical);
  }
}

// Hero 영역
.wait-hero {
  padding: 3.125vw 0 1.5625vw;

  @include md {
    padding: 45px 0 32px;
    max-width: 70vw;
    margin: 0 auto;
  }
  @include sm {
    padding: 48px 20px;
  }

  .title {
    @include h1;
    text-align: center;
    color: $gray100;
    max-width: none;

    @include sm {
      text-align: left;
    }
  }

  .description {
    margin-top: 0.7812vw;
    color: $gray100;
    text-align: center;
    white-space: pre-line;

    @include body-1;

    @include md {
      margin-top: 12px;
    }
    @include sm {
      margin-top: 12px;
      text-align: left;
    }
  }

  .ico-scroll {
    margin-top: 3.9vw;
    color: $gray100;
    font-size: 12px;
    font-weight: 500;
    display: inline-flex;
    gap: 4px;
    text-align: center;

    @include md {
      margin-top: 56px;
    }

    @include sm {
      display: none;
    }
    svg {
      animation: jump-arrow 2.5s infinite;
    }
  }
}

.wait-inner {
  max-width: 1360px;
  margin: 0 auto;
  text-align: center;

  @include sm {
    text-align: left;
  }
}

.wait-protocol,
.wait-governance {
  background-color: $purple;
}

// Protocol 영역
.wait-protocol {
  .title {
    @include h4;
    @include h3-5;
    white-space: pre-line;
    padding: 3.125vw 0;

    @include md {
      padding: 48px 0;
      max-width: 70vw;
      margin: 0 auto;
      white-space: normal;
    }
    @include sm {
      padding: 40px 20px;
      white-space: normal;
    }
  }

  .protocol-list {
    display: flex;
    width: 100%;
    border-top: 1px solid $gray50;

    @include sm {
      flex-direction: column;
    }

    & > li {
      position: relative;
      width: 100%;
      padding: 40px;

      @include sm {
        padding: 20px 20px 28px;
      }

      &::after {
        @include content;

        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        border-right: 1px solid $gray50;

        @include sm {
          border-right: 0;
          height: 0;
          width: 100%;
          border-top: 1px solid $gray50;
        }
      }

      &:last-child {
        &::after {
          display: none;

          @include sm {
            display: block;
          }
        }
      }
      &:first-child {
        &::after {
          @include sm {
            display: none;
          }
        }
      }
    }

    .tag {
      @include h6;

      display: inline-flex;
      padding: 0.3125vw 0.9375vw;
      border: 1px solid $gray20;
      border-radius: 100px;

      @include md {
        padding: 5px 13px;
      }
      @include sm {
        padding: 4px 10px;
      }
    }

    .img-wrap {
      position: relative;
      margin-top: 1.5625vw;
      padding-top: 15.04vw;
      overflow: hidden;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        height: 100%;
      }
      @include md {
        margin-top: 17px;
        padding-top: 15vw;
      }
      @include sm {
        margin-top: 0;
        height: 55.55vw;
        max-height: 320px;
        padding-top: 0;
        img {
          position: absolute;
          left: 50%;
          top: 0;
          width: auto;
          height: 100%;
          transform: translateX(-50%);
        }
      }
    }

    .content {
      margin-top: 1.5625vw;
      @include md {
        margin-top: 17px;
      }

      .list-title {
        @include h2;
        @include h1-2;
      }
      .list-description {
        margin-top: 0.625vw;
        @include body-1;
        @include md {
          margin-top: 9px;
        }
        @include sm {
          margin-top: 8px;
        }
      }
    }
  }
}

// Structure 영역
.wait-structure {
  .half-inner {
    display: flex;

    @include sm {
      flex-direction: column;
    }

    & > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }

  .title-wrap {
    padding: 40px;
    padding-right: 16vw;

    @include sm {
      padding: 40px 20px 20px;
      justify-content: flex-start;
    }
    .title {
      @include h3;
      @include h3-5;
      color: $gray100;
      @include sm {
        width: 100%;
      }
    }

    .description {
      margin-top: 1.25vw;
      color: $gray100;
      @include body-1;
      @include md {
        margin-top: 18px;
      }
      @include sm {
        margin-top: 16px;
      }
    }
  }
  .img-wrap {
    position: relative;
    padding: 0 2.46vw;

    @include sm {
      padding: 0;
    }

    img {
      width: 100%;
      height: auto;

      @include sm {
        width: auto;
        height: 100vw;
        max-height: 480px;
      }
    }

    &::after {
      @include content;
      position: absolute;
      width: 0;
      height: 100%;
      left: 0;
      top: 0;
      border-left: 1px solid $gray50;

      @include sm {
        display: none;
      }
    }
  }
}

// Governance 영역
.wait-governance {
  padding: 3.9vw 0;

  @include md {
    padding: 56px 40px;
  }
  @include sm {
    padding: 40px 0 0;
  }

  .title-wrap {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;

    @include md {
      max-width: 70vw;
    }
    .title {
      @include h3;
      @include h3-5;

      @include sm {
        text-align: center;
      }
    }

    .description {
      @include body-1;
      margin-top: 1.093vw;

      @include md {
        margin-top: 16px;
      }
      @include sm {
        margin-top: 16px;
        text-align: center;
      }
    }
  }

  .governance-list {
    width: 100%;
    display: flex;
    margin-top: 2.812vw;

    @include md {
      margin-top: 40px;
    }
    @include sm {
      margin-top: 40px;
      flex-wrap: wrap;
    }

    & > * {
      position: relative;
      width: 100%;
      text-align: left;
      padding: 0 1.25vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include md {
        padding: 0 18px;
      }
      @include sm {
        padding: 20px 15px 24px 20px;
      }

      &::after {
        @include content;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        border-right: 1px solid $gray50;
      }

      &:first-child {
        @include sm {
          &::before {
            @include content;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 0;
            border-top: 1px solid $gray50;
          }
          &::after {
            width: 100%;
            height: 0;
            bottom: 0;
            top: auto;
            border-bottom: 1px solid $gray50;
          }
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      &:not(:first-child) {
        @include sm {
          width: 33.333%;
        }
      }
    }

    dt {
      @include h6;
    }
    dd {
      @include h3;
      @include h3-5;
      display: flex;
      width: 100%;
      gap: 0.66vw;
      margin-top: 0.3125vw;

      @include md {
        gap: 10px;
        margin-top: 5px;
      }
      @include sm {
        gap: 8px;
        margin-top: 6px;
      }
    }
  }

  .img-wrap {
    display: inline-flex;
    width: 2.8125vw;

    @include md {
      width: 40px;
    }
    @include sm {
      width: 28px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.wait-voting {
  border-bottom: 1px solid $gray50;
}
.wait-voting,
.wait-investment {
  display: flex;
  @include sm {
    flex-direction: column;
  }

  .title-wrap {
    width: 33.333%;
    padding: 40px;

    @include sm {
      width: 100%;
      padding: 32px 20px 20px;
    }

    .title {
      @include h3;
      @include h3-5;

      color: $gray100;
    }

    .date {
      display: block;
      color: $gray70;
      font-size: 14px;
      line-height: 1.5;
      margin-top: 0.625vw;
      @include md {
        margin-top: 8px;
      }
    }

    & + .wait-voting-list-wrap,
    & + .table-wrap,
    & + .empty-wrap {
      width: calc(100% - 33.333%);
      position: relative;
      text-align: center;
      justify-content: center;
      @include sm {
        width: 100%;
      }

      &::after {
        @include content;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        border-right: 1px solid $gray50;

        @include sm {
          display: none;
        }
      }
    }
  }
}

.empty-wrap {
  @include body-1;
  text-align: center;
  color: $gray70;
}

// Investment 영역
.wait-investment {
  position: relative;

  &::after {
    @include content;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    border-top: 1px solid $gray50;
  }

  .table-wrap {
    padding: 40px 0 3.125vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include md {
      padding: 40px 0 60px;
    }
    @include sm {
      padding: 0;
    }

    &.has-more {
      padding-bottom: 0;

      @include md {
        padding-bottom: 0;
      }
      :global {
        .ant-table-tbody > tr:last-child > td {
          @include sm {
            border-color: transparent;
          }
        }
      }
    }

    .empty-wrap {
      margin-bottom: -1.5625vw;
      @include md {
        margin-bottom: -20px;
      }

      @include sm {
        border-top: 1px solid $gray50;
        border-bottom: 1px solid $gray50;
        padding: 48px 0 48px;
        margin-bottom: 0;
      }
    }

    :global {
      .ant-table-thead > tr > th {
        background-color: transparent;
        color: $gray100;
        border-color: $gray50;
        padding: 0 0 20px 40px;

        @include body-1;

        @include md {
          padding: 0 0 12px 24px;
        }
        @include sm {
          padding: 12px 0 20px 12px;
        }
      }
      .ant-table-tbody > tr > td {
        border-color: $gray50;
        padding: 20px 0 20px 40px;
        color: $gray100;
        vertical-align: middle;
        @include body-2;

        @include md {
          padding: 12px 0 12px 20px;
        }
        @include sm {
          padding: 12px 0 12px 20px;
        }

        a {
          display: inline-flex;
          align-items: center;
          gap: 4px;
          font-feature-settings: "calt" off;

          &:hover {
            color: $gray100;

            svg {
              opacity: 1;
              @include sm {
                display: none;
              }
            }
          }

          svg {
            opacity: 0;
            transition: opacity 0.2s;
          }

          & > span {
            @include ellipsis(1);
          }
        }
      }
      .ant-table-tbody > tr:last-child > td {
        border-color: transparent;

        @include sm {
          border-color: $gray50;
        }
      }
      .ant-table-tbody
        > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
        > td {
        background-color: $gray30;

        @include sm {
          background-color: transparent;
        }
      }
      .ant-table-placeholder {
        background-color: transparent;
        border: none;
        padding-top: 3.125vw;

        @include md {
          padding-top: 60px;
        }
        @include sm {
          padding: 32px 0 40px;
        }
      }
    }
  }

  .table-btn-wrap {
    border-top: 1px solid $gray50;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include sm {
      border-bottom: 1px solid $gray50;
    }

    a {
      @include body-2;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 4px;
      color: $gray100;
      align-self: center;
      transition: background-color 0.2s;

      @include md {
        padding: 16px 10px;
      }

      @include sm {
        padding: 16px 10px;
      }

      &:hover {
        background-color: $gray30;

        @include sm {
          background-color: transparent;
        }
      }

      svg {
        width: 16px;
        height: 16px;
        transform: rotate(-90deg);

        path {
          fill: $gray100;
        }
      }
    }
  }
}

// Voting 영역
.wait-voting {
  position: relative;
  &::before {
    @include content;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    border-top: 1px solid $gray50;

    @include sm {
      display: none;
    }
  }
  .empty-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 40px;
    @include sm {
      border-top: 1px solid $gray50;
      padding: 48px 20px 48px;
    }
  }
  .wait-voting-list-wrap {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 50%;
      position: relative;
      padding: 40px 32px;
      transition: background-color 0.3s;
      will-change: background-color;

      &:hover {
        background-color: $gray30;
      }

      a {
        display: block;
        width: 100%;
        text-align: left;
      }

      @include md {
        padding: 40px 24px;
      }
      @include sm {
        width: 100%;
        padding: 28px 20px;
        &::before {
          @include content;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 0;
          border-top: 1px solid $gray50;
        }
      }
      &:nth-of-type(2n) {
        & ~ li {
          &::before {
            @include content;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 0;
            border-top: 1px solid $gray50;
          }
        }
        &::after {
          @include content;
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 100%;
          border-left: 1px solid $gray50;

          @include sm {
            display: none;
          }
        }
      }

      .title {
        @include h4;
        @include h3-5;
        margin: 0;
        color: $gray100;
      }
    }

    .wait-voting-list-more {
      a {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
    }

    [class*="voting-chart-mini"] {
      width: 72px;

      @include md {
        width: 40px;
      }

      @include sm {
        width: 40px;
      }
    }
  }
}

.voting-list-block-wrap {
  .wallet-info {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .ncp-info {
      display: flex;
      width: 100%;
      gap: 30px;
      align-items: center;
      max-width: calc(100% - 120px);

      @include sm {
        gap: 16px;
        max-width: calc(100% - 100px);
      }
      span {
        position: relative;
        display: inline-flex;
        @include body-1;
      }

      .ncp-name {
        color: $gray100;
        span {
          @include ellipsis(1);
        }

        @media (min-width: 1120) and (max-width: 1150px) {
          display: none;
        }
        &::after {
          @include content;
          position: absolute;
          right: -15px;
          top: 1px;
          width: 0;
          height: calc(100% - 2px);
          border-left: 1px solid $gray50;

          @include sm {
            right: -8px;
          }
        }
      }
      .ncp-address {
        color: $gray70;
        font-feature-settings: "ss20" on, "lnum" on, "tnum" on;
      }
    }
  }
  .list-title-wrap {
    margin-top: 0.625vw;

    @include md {
      margin-top: 14px;
    }
    @include sm {
      margin-top: 14px;
    }
  }
  .list-desc-wrap {
    @include body-1;
    margin-top: 0.39vw;
    color: $gray80;

    @include ellipsis(3);

    word-break: break-all;

    @include md {
      margin-top: 6px;
    }
    @include sm {
      margin-top: 6px;
    }
  }

  .list-bottom-info {
    position: relative;
    margin-top: 1.5625vw;
    padding-bottom: 2.34vw;
    color: $gray100;

    @include md {
      margin-top: 22px;
      padding-bottom: 42px;
    }
    @include sm {
      margin-top: 22px;
      padding-bottom: 40px;
    }

    :global {
      .icon-with-text-wrap {
        flex-direction: column;
        gap: 4px;
        .icon-with-text {
          @include body-2;

          &:nth-of-type(1) {
            width: 100%;
          }
          &:nth-of-type(2) {
            &::before {
              display: none;
            }
          }

          .voting-chart-mini {
            margin-left: 1vw;

            @include md {
              margin-left: 18px;
            }
          }
        }
      }
    }
    .tx-state {
      position: absolute;
      bottom: 0;
      left: 0;

      @include body-1;

      &.tx-before {
        color: $gray70;
      }
      &.tx-complete {
        color: $positive;
      }
    }
  }
}
.tx-hash-num {
  span {
    font-feature-settings: "ss20" on, "lnum" on, "tnum" on;
  }
}
