@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

.pagination-container {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 32px;
  transition: none;

  .btn-first,
  .btn-last {
    width: 36px;
    height: 36px;
    padding: 10px;

    &.disabled {
      cursor: not-allowed;

      svg {
        path,
        rect {
          fill: $gray40;
        }
      }
    }

    svg {
      vertical-align: top;
    }
  }

  .btn-first {
    svg {
      transform: rotate(180deg);
    }
  }

  :global {
    .ant-pagination {
      display: flex;
    }

    .ant-pagination-simple {
      @include body-2;

      display: flex;
      align-items: center;
      margin: 0 4px;
      color: rgba($gray100, 0.8);

      input {
        @include body-2;

        min-width: 56px;
        height: 32px;
        margin-right: 0;
        color: $gray90;
        text-align: center;
        background: transparent;
        border: 1px solid rgba($gray100, 0.2);
        padding: 6px 8px;
        border-radius: 0;

        &:hover {
          border-color: $gray60;
        }

        &:focus-visible {
          border-color: $gray80;
        }
      }

      .ant-pagination-simple-pager {
        height: auto;
        margin: 0;
      }
      .ant-pagination-slash {
        margin: 0 7px;
      }
    }

    .ant-pagination-prev {
      padding: 10px;
      margin-right: 20px;
      width: 36px;
      height: 36px;
      line-height: inherit;
      cursor: pointer;
      transition: none;

      svg {
        transform: rotate(90deg);
        width: 16px;
        height: 16px;
        path {
          fill: $gray100;
        }
      }
    }

    .ant-pagination-next {
      padding: 10px;
      margin-left: 20px;
      width: 36px;
      height: 36px;
      line-height: inherit;
      cursor: pointer;
      transition: none;

      svg {
        transform: rotate(-90deg);
        width: 16px;
        height: 16px;
        path {
          fill: $gray100;
        }
      }
    }

    .ant-pagination-disabled {
      cursor: not-allowed;
      svg {
        path {
          fill: $gray40;
        }
      }
    }
  }
}
