@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
// core 관련 색상, mixin (font) 정의
@import "../../assets/scss/core";

.header {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background: $gray20;
  border-bottom: 1px solid $gray50;
  // dark mode
  &.dark {
    @include theme-map($theme-dark);
  }

  // light mode
  &.light {
    @include theme-map($theme-light);
  }

  @include sm {
    padding: 0 20px 0;
  }

  .header-logo-wrap {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .header-logo {
    $animation-time: 1s;
    $logo-padding: 92px;
    a {
      position: relative;
      display: flex;
      gap: 8px;
      align-items: center;
      padding-left: $logo-padding;
      animation: logoMove 0.6s forwards 2s;

      @include md {
        animation: none;
        padding-left: 0;
      }

      @include sm {
        animation: none;
        padding-left: 0;
      }

      .logo {
        &.logo-typo-wemix {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 84px;
          height: 20px;
          background: url("#{$publicPath}/assets/images/logo_typo_wemix.svg")
            no-repeat center;
          animation: logoOpacity $animation-time forwards 1.6s;
          @include md {
            display: none;
          }
          @include sm {
            display: none;
          }
        }
        &.logo-typo-governance {
          width: 156px;
          height: 22px;
          background: url("#{$publicPath}/assets/images/logo_typo_governance.svg")
            no-repeat center;
          @include sm {
            // display: none;
          }
        }
        &.logo-symbol {
          width: 36px;
          height: 36px;
        }

        @keyframes logoOpacity {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

        @keyframes logoMove {
          0% {
            padding-left: $logo-padding;
          }
          100% {
            padding-left: 0;
          }
        }
      }
    }
  }

  .header-gnb {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 0;
    border-right: none;
    background: transparent;

    @include md {
      position: static;
      transform: none;
    }

    @include sm {
      position: static;
      transform: none;
    }

    li {
      padding: 0 !important;
      &.active {
        &.ant-menu-item-selected {
          background-color: transparent;
        }
        a {
          font-weight: 600;
          color: $purple2;
          text-decoration: underline;
          text-underline-offset: 23px;
          text-decoration-thickness: 2px;
        }
      }

      a {
        display: block;
        padding: 8px 16px;
        color: $gray100;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-transform: capitalize;
        white-space: nowrap;

        &:hover {
          color: $purple2;
        }

        &:active {
          background-color: transparent;
        }
      }
    }
  }

  .header-utils {
    display: flex;
    align-items: center;
    gap: 12px;
    .user-wallet {
      button {
        padding: 10px 16px;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        background-color: $gray100;
        border-radius: 44px;
        white-space: nowrap;
      }
    }

    .lang-wrap {
      .btn-open-lang {
        padding-right: 7px;
      }
      .lang-list-wrap {
        padding-top: 5px;
        .lang-list {
          &::before {
            content: "";
            position: absolute;
            top: 5px;
            right: 11px;
            width: 0;
            height: 0;
            transform: translateY(-100%);
            border-bottom: 6px solid $gray30;
            border-top: 6px solid transparent;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
          }
        }
      }

      .btn-lang-change {
        display: inline-flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: background-color 0.2s;
        outline: none;

        &:hover {
          background-color: $gray30;
        }

        &:focus-visible,
        &:active {
          border: 1px solid $gray100;
        }

        @include sm {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  .btn-open-menu {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    color: $gray100;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
  }
  // &-mobile-gnb {
  //   width: 162px;
  //   .ant-dropdown-menu-item:hover,
  //   .ant-dropdown-menu-submenu-title:hover {
  //     background: none;
  //   }
  //   .ant-dropdown-menu {
  //     padding: 0;
  //     border-radius: 0;
  //     background: $gray30;

  //     a {
  //       padding: 12px 16px 13px;
  //       color: $gray100;
  //       font-size: 16px;
  //       font-weight: 600;
  //       line-height: 19px;
  //     }
  //   }
  // }
  .mobile-gnb {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;
    height: calc($vh * 100);
    left: 0;
    top: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    .gnb-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($gray10, 0.3);
    }

    &.show {
      transform: translateX(0);
      .gnb-inner {
        transform: translateX(0);
      }
    }
    &.leave {
      .gnb-inner {
        transform: translateX(100%);
      }
    }
    .gnb-inner {
      position: relative;
      z-index: 10;
      top: 0;
      right: 0;
      width: 100%;
      min-width: 280px;
      max-width: 390px;
      background-color: $gray30;
      transform: translateX(390px);
      transition: transform 0.3s;
      height: 100%;
      padding: 18px 0 0;
      text-align: right;
      .btn-lang-change {
        position: absolute;
        top: 0;
        left: 20px;
      }
      .header-gnb {
        position: static;
        transform: none;
        flex-direction: column;
        text-align: left;
        gap: 0;
        li {
          margin-bottom: 0;
          margin-top: 0;
          padding: 0 20px !important;
          height: auto;
          line-height: 20px;
          &.active {
            a {
              text-decoration: none;
              color: $purple;
            }
          }
          a {
            padding: 12px 0 16px;
            font-weight: 700 !important;
            font-size: 16px;
            color: $gray100;
          }
          & + li {
            a {
              padding-top: 16px;
            }
          }
        }
      }
    }
    .btn-close {
      position: relative;
      right: 20px;
      margin-bottom: 10.5px;
      svg {
        path {
          fill: $gray100;
        }
      }
    }
  }
  .header-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 41.5px);
    justify-content: space-between;
    padding-top: 24px;
    overflow-y: auto;

    &::before {
      @include content;
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $gray50;
    }
  }
  .header-my-info {
    display: flex;
    align-items: center;
    gap: 20px;
    @include sm {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
    dl {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      gap: 17px;
      white-space: nowrap;
      @include sm {
        display: table;
      }
      div {
        display: flex;
        gap: 4px;
        @include sm {
          display: table-row;
        }
        dt {
          color: $gray60;
          @include sm {
            display: table-cell;
            text-align: left;
            padding-top: 4px;
          }
        }
        dd {
          @include sm {
            text-align: left;
            padding-top: 4px;
            padding-left: 4px;
            display: table-cell;
            color: $gray40;
          }
          color: $gray80;
        }
        & + div {
          position: relative;
          &::before {
            @include content;
            position: absolute;
            left: -8px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 11px;
            background-color: #737373;
            @include sm {
              display: none;
            }
          }
        }
      }
    }
    @include sm {
      // .button-wrap.outline {
      //   color: $gray10;
      //   border-color: $gray10;
      // }
    }

    .btns-wrap {
      display: flex;
      margin-top: 0;
      gap: 12px;

      button {
        font-feature-settings: "calt" off;
      }
    }
  }
}
