.landing-wrap {
  background-color: $gray20;
  display: flex;
  flex-direction: column;
  min-height: calc($vh * 100);
}
.a11y {
  display: block !important;
  padding: 0 !important;
  margin: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  word-break: initial !important;
  word-wrap: initial !important;
}

.bg-black {
  background-color: $gray20;
  color: $gray100;
}

.bg-purple {
  background-color: $purple;
}

.bg-skyblue {
  background-color: $skyblue;
}

.section-wrap {
  &.section-wonder-about {
    .inner-row.title-section {
      padding: 4.218vw 0;

      @include md {
        padding: 74px 0 72px;
      }

      @include sm {
        padding: 40px 20px;
      }
    }
    h2 {
      max-width: 68.438vw;
      padding: 0 1.563vw;
      margin: 0 auto;
      @include h3-5;

      @include md {
        max-width: 859px;
        padding: 0;
      }

      @include sm {
        max-width: none;
        padding: 0;
      }

      br {
        @include sm {
          display: none;
        }
      }
    }
  }

  &.section-our {
    .title-wrap {
      padding: 40px;

      h2 {
        @include sm {
          margin-bottom: 12px;
          font-size: 32px;
          line-height: 39px;
          font-weight: 600;
        }
      }
      @include lg {
        padding: 32px 40px;
      }

      @include md {
        padding: 32px;
      }

      @include sm {
        padding: 24px 20px;
      }
      .desc {
        margin-top: 8px;
      }
    }
  }

  &.section-role {
    h2 {
      @include h1-2;
    }
    .desc-sm {
      font-size: 14px;
      line-height: 21px;

      sup {
        font-size: 10px;
      }
    }
  }
  &.section-become {
    .title-section.inner-row {
      padding-left: 40px;
      padding-right: 40px;

      @include sm {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    h3 {
      @include h3-5;

      @include sm {
        white-space: pre-line;
      }
    }
  }
  &.section-roadmap {
    h3 {
      @include h1-2;

      @include sm {
        white-space: pre-line;
      }
    }
    .desc {
      max-width: 1006px;

      @include md {
        max-width: 793px;
      }
    }
    ol.list-wrap {
      & > li.active {
        padding-bottom: 40px;
      }
    }
  }
  &.section-wait {
    border-top: 1px solid $gray50;

    h3 {
      @include h3-5;

      @include sm {
        max-width: 278px;
      }
    }

    .title-section.inner-row {
      padding: 94px 40px 109px;

      @include md {
        padding: 56px 40px;
      }

      @include sm {
        padding: 40px 20px;
        text-align: center;
      }
    }

    .btn {
      border: 1px solid $gray50;
    }
  }
}

.desc {
  font-weight: 500;
  font-size: 18px;

  line-height: 27px;
  @include md {
    font-size: 16px;
    line-height: 24px;
  }
  @include sm {
    font-size: 14px;
    line-height: 21px;
  }

  &-sm {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    @include md {
      font-size: 14px;
      line-height: 21px;
    }
    @include sm {
      font-size: 14px;
      line-height: 21px;
    }

    span {
      display: block;
      font-size: 16px;
      line-height: 24px;

      @include md {
        font-size: 14px;
        line-height: 21px;
      }
      @include sm {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  sup {
    position: relative;
    top: -0.4em;
    font-size: 12px;

    @include md {
      font-size: 10px;
    }
    @include sm {
      font-size: 10px;
    }
  }
}

.title-wrap {
  span:not(.desc) {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 600;
    @include md {
      font-size: 20px;
      line-height: 24px;
    }
    @include sm {
      font-size: 16px;
      line-height: 20px;
    }
    & + h3 {
      margin-top: 8px;
      @include sm {
        margin-top: 17px;
      }
    }
  }
  h2,
  .h2 {
    @include h2;
  }
  h3,
  .h3 {
    @include h3;
  }
  h4,
  .h4 {
    @include h4;
  }

  & + .desc {
    margin-top: 28px;

    @include md {
      margin-top: 16px;
    }
    @include sm {
      margin-top: 20px;
    }
  }
}

.desc-box {
  padding-top: 29px;
  .desc {
    sup {
      position: relative;
      top: -7px;
      font-size: 10px;
    }
    & + .desc {
      padding-top: 16px;
    }
  }
  @include md {
    padding-top: 20px;
  }
  @include sm {
    padding: 20px 20px 0;
  }
}

.column-type {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  .ncp-roles-container {
    display: grid;
    grid-template-columns: auto 1fr;
    width: 50%;
    @include sm {
      width: 100%;
    }
    &::after {
      @include content;
      position: absolute;
      top: 0;
      right: 0;
      border-left: 1px solid $gray50;
      height: 100%;
    }

    .ncp-logo-box {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      &::after {
        @include content;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        border-right: 1px solid $gray50;
      }

      @include sm {
        img {
          width: 160px;
        }
      }
    }

    .ncp-list-info {
      padding: 20px;
      strong {
        display: block;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
      }
    }
  }
  .inner-column {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    height: auto;
    &::after {
      @include content;
      position: absolute;
      top: 0;
      right: 0;
      border-left: 1px solid $gray50;
      height: 100%;
    }
    &:nth-of-type(2),
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    @include sm {
      width: 100%;
      &::after {
        display: none;
      }
    }
    &.visual-desc {
      padding-right: 40px;
      @include sm {
        padding-right: 20px;
      }
      &::after {
        @include sm {
          display: none;
        }
      }
    }
  }
  @at-root .visual-list & {
    li {
      position: relative;
      display: flex;
      align-items: center;
      .list-visual {
        position: relative;
        padding: 18px 0;
        width: 240px;
        text-align: center;

        @include md {
          padding: 60px 0;
        }
        @include sm {
          padding: 44px 0;
          width: 130px;
          min-height: 315px;
          overflow: hidden;
        }

        &::after {
          @include content;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          border-right: 1px solid $gray50;
        }

        img {
          width: 200px;
          height: 200px;
          @include sm {
            position: absolute;
            left: -81px;
            top: 50%;
            transform: translateY(-50%);
            width: 227px;
            height: 227px;
          }
        }
      }
      .list-title {
        width: calc(100% - 240px);
        padding: 0 34px;
        strong {
          display: block;
          font-size: 18px;
          line-height: 22px;
          font-weight: 600;
        }
        .desc {
          margin-top: 6px;
        }
        @include md {
          padding: 0 28px;
        }
        @include sm {
          padding: 0 20px;
          width: calc(100% - 130px);
          strong {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
      &:nth-of-type(1),
      &:nth-of-type(2) {
        border-bottom: 1px solid $gray50;
      }
      .desc {
        color: $gray80;
        sub {
          font-size: 10px;
        }
      }
      @include sm {
        border-bottom: 1px solid $gray50;
      }
    }
  }
}
// .project-card-list {
//   display: grid;
//   justify-content: center;
//   margin-top: 12px;
//   gap: 8px;
//   grid-template-columns: repeat(auto-fit, minmax(302px, 1fr));

//   @include tablet {
//     margin-top: 16px;
//     gap: 16px;
//     grid-template-columns: repeat(auto-fit, minmax(296px, 1fr));
//   }

//   @include desktop {
//     margin-top: 16px;
//     gap: 24px;
//   }
// }

// li {
//   width: 100%;
//   max-width: 450px;
//   justify-self: center;

//   @include desktop {
//     border-radius: 4px;
//   }
// }
.visual-list {
  &.list-type {
    @include sm {
      overflow: hidden;
    }
    ul {
      border-top: 1px solid $gray50;
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(auto-fit, minmax(640px, 1fr));
      @include md {
        grid-template-columns: repeat(auto-fit, minmax(504px, 1fr));
      }
      @include sm {
        overflow: hidden;
        border-top: 0;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        width: calc(100% + 1px);
      }

      li {
        border-bottom: 1px solid $gray50;
        border-right: 1px solid $gray50;
        display: flex;
        @include sm {
          display: block;
          &:last-of-type {
            border-bottom: 0;
          }
        }

        .list-visual {
          position: relative;
          padding: 0 32px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 220px;
          pointer-events: none;
          &::after {
            @include content;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            border-right: 1px solid $gray50;
          }
          img {
            display: inline-block;
          }
          svg,
          img {
            width: 140px;
            height: auto;
            aspect-ratio: 1/1;
          }
          @include sm {
            width: 100%;
            padding: 24px 0;
            min-height: auto;
            aspect-ratio: 1/1;
            text-align: left;
            background-color: transparent;
            transition: background-color 0.3s;
            pointer-events: visible;
            &.active {
              background-color: $gray30;
            }
            &::after {
              display: none;
            }
            svg,
            img {
              width: calc(100% - 48px);
            }
          }
        }

        .list-title {
          display: flex;
          align-content: flex-start;
          flex-wrap: wrap;
          padding: 40px 32px;

          @include lg {
            transform: none !important;
          }

          @include md {
            padding: 32px;
            transform: none !important;
          }

          @include sm {
            height: 0;
            overflow: hidden;
            background-color: $gray20;
            transition: height 0.3s;
            padding: 0 !important;

            [data-aos^="fade"][data-aos^="fade"] {
              opacity: 1 !important;
              transition-duration: 0 !important;
              transform: none !important;
            }
            &.active {
              position: relative;
              z-index: 10;
              border-top: 1px solid $gray50;
              padding: 24px 20px !important;
              height: auto;
              display: block;
              width: 100vw;
            }
          }

          a {
            display: inline-flex;
            align-items: center;
            gap: 6px;
            &:hover {
              strong {
                text-decoration: underline;
                text-underline-offset: 2px;
                color: $gray100;
              }
            }
            strong {
              display: inline-flex;
              align-items: center;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              .outlist-tag {
                margin-left: 10px;
                display: inline-block;
                background-color: $gray100;
                color: $gray10;
                font-size: 14px;
                font-weight: 700;
                padding: 0 5px;
                line-height: 20px;
                flex-shrink: 0;
              }
              @include sm {
                font-size: 16px;
                line-height: 20px;
                .outlist-tag {
                  font-size: 12px;
                  margin-left: 8px;
                  padding: 0 4px;
                  line-height: 18px;
                }
              }
            }
            & + .desc {
              margin-top: 4px;
              color: $gray80;
              @include sm {
                margin-top: 6px;
              }
            }
          }
          @include md {
            width: calc(100% - 206px);
            padding: 20px 22px;
          }
          @include sm {
            width: 100%;
            padding: 0 20px 32px;
          }
        }
      }
    }
    @include sm {
      border-top: 1px solid $gray50;
    }
  }
}

.inner-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  & > * {
    width: 100%;
  }
  &.title-section {
    &::after {
      @include content;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid;
      @at-root .bg-purple & {
        border-color: $gray20;
      }
      @at-root .bg-black & {
        border-color: $gray50;
      }
    }
    text-align: center;
    @include sm {
      text-align: left;
    }
    @at-root .bg-purple & {
      padding: 99px 0 98px;

      @include md {
        padding: 79px 0 80px;
      }
      @include sm {
        padding: 28px 20px;
      }
    }
    @at-root .bg-skyblue & {
      padding: 3.828vw 0;
      @include md {
        padding: 63px 0 67px;
      }
      @include sm {
        padding: 34px 20px 45px;
      }
      .title-wrap + .desc {
        margin-top: 8px;
        @include sm {
          margin-top: 4px;
        }
      }
      .desc {
        white-space: pre-line;

        @include sm {
          white-space: normal;
        }
      }
    }
    @at-root .bg-black & {
      padding: 53px 0;
      @include md {
        padding: 42px 0 46px;
      }
      @include sm {
        padding: 19px 20px 23px;
      }
    }
  }
}

.list-wrap {
  display: flex;
  &.governance {
    & > li {
      display: flex;
      width: calc(100% / 3);
      flex-direction: column;
      padding-bottom: 32px;

      @include md {
        padding: 32px;
      }

      @include sm {
        padding: 20px;
        width: 100%;
      }
    }

    @include sm {
      flex-direction: column;
    }
  }
  & > li {
    position: relative;
    padding: 32px 40px 72px;
    width: 100%;
    height: 100%;
    @include md {
      padding-bottom: 56px;
    }
    @include sm {
      padding: 24px 20px 20px;
      height: auto;
      &:last-of-type {
        padding-bottom: 20px;
      }
    }
    .list-title-wrap {
      min-height: calc(4.140625vw + 25px + 41px + 24px);

      @include md {
        min-height: calc(53px + 25px + 41px + 24px);
      }

      @include sm {
        min-height: auto;
      }
    }
    .list-title {
      display: block;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      @include md {
        font-size: 18px;
        line-height: 22px;
      }
      @include sm {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .list-title-desc {
      display: block;
      margin-top: 20px;
      font-size: 14px;
      line-height: 21px;

      @include md {
        margin-top: 24px;
      }

      @include sm {
        margin-top: 16px;
      }
    }
    .list-content {
      position: relative;

      .bi-wrap {
        display: flex;
        align-items: center;
        gap: 8px;

        @include sm {
          gap: 4px;
        }

        img {
          width: 2.813vw;
          height: 2.813vw;

          @include md {
            width: 40px;
            height: 40px;
          }

          @include sm {
            width: 24px;
            height: 24px;
          }
        }
      }

      strong {
        display: block;
        font-weight: 500;
        @include h1;
        @include md {
          font-size: 48px;
          line-height: 53px;
        }
        @include sm {
          font-weight: 600;
          font-size: 32px;
          line-height: 39px;
        }

        & + span:not(.tag) {
          display: block;
          margin-top: 4px;
          font-size: 14px;
          line-height: 150%;
          font-weight: 500;
          color: $purple3;

          @include md {
            margin-top: 6px;
            font-size: 12px;
          }

          @include sm {
            margin-top: 8px;
          }
        }
      }
    }

    &::after {
      @include content;
      position: absolute;
      top: 0;
      right: 0;
      border-left: 1px solid $gray50;
      height: 100%;
      @include sm {
        border-left: none;
        width: 100%;
        height: auto;
        bottom: 0;
        top: auto;
        border-bottom: 1px solid $gray50;
      }
    }
    &:last-child {
      &::after {
        display: none;
      }
    }

    .desc {
      margin-top: 24px;

      @include md {
        margin-top: 20px;
      }

      @include sm {
        margin-top: 16px;
      }

      p {
        white-space: pre-line;
        & + p {
          @include body-1;
          margin-top: 27px;

          @include md {
            margin-top: 24px;
          }
          @include sm {
            margin-top: 16px;
          }
        }
      }

      .link {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: $gray20;
      }
    }

    sub {
      font-size: 10px;
    }
  }
  @include sm {
    flex-wrap: wrap;
    .btn-toggle-arrow {
      & + .desc {
        overflow: hidden;
        margin-top: 0;
        transition: height 300ms, margin 300ms;
      }
      &:not(.active) + .desc {
        height: 0 !important;
      }
      &.active {
        & + .desc {
          margin-top: 30px;
        }
      }
    }
  }
}

.list-inner-list {
  display: flex;
  margin-top: -14px;

  @include md {
    display: flex;
    gap: 0;
    margin-top: -16px;
  }
  @include sm {
    margin-top: -16px;
  }

  & > li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 33px;
    width: 100%;
    .inner-list-tit-wrap {
      & > span {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        gap: 2px;
        font-size: 14px;
        line-height: 1.5;
        @include md {
          font-size: 12px;
          line-height: 18px;
        }
        @include sm {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .data {
      display: block;
      font-weight: 600;
      width: 100%;
      @include h3;
      @include h3-5;
    }
    .data-desc {
      display: block;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;

      @include md {
        margin-top: 4px;
        font-size: 12px;
        line-height: 18px;
      }
      @include sm {
        margin-top: 7px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  @include sm {
    flex-wrap: nowrap;
  }
}

.btn-toggle-arrow {
  display: none;
  @include sm {
    position: absolute;
    display: block;
    right: 0;
    top: 5px;
    width: 24px;
    height: 24px;
    transition: transform 300ms;
    will-change: transform;
  }
  &.active {
    transform: rotate(180deg);
  }
}

.btn-arrow {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 238px;
  height: 48px;
  background-color: $gray20;
  color: $gray100;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  @include sm {
    margin-top: 16px;
  }

  &:hover {
    color: $gray100;
  }
}

.btn-wrap {
  margin-top: 32px;

  @include md {
    margin-top: 20px;
  }
  @include sm {
    margin-top: 16px;
  }
}

.visual-wrap {
  padding: 0 40px;
  position: relative;
  height: 37.89vw;
  @include md {
    height: auto;
    min-height: 520px;
  }
  @include sm {
    padding: 0;
    height: auto;
  }
  h2 {
    @include h1;
    width: 100%;
  }
  .visual-desc {
    padding-top: 60px;
    align-content: flex-start;

    @include md {
      padding-bottom: 88px;
      padding-right: 40px;
    }
    @include sm {
      order: 2;
      padding: 40px 20px;
      border-top: 1px solid $gray50;
    }

    .desc {
      margin-top: 20px;
      max-width: 564px;
    }
  }
  .ico-scroll {
    display: flex;
    align-items: center;
    gap: 4px;
    position: absolute;
    left: 40px;
    bottom: 53px;
    font-size: 14px;
    line-height: 21px;
    @include md {
      font-size: 12px;
      line-height: 18px;
    }
    @include sm {
      display: none;
    }
    svg {
      animation: jump-arrow 2.5s infinite;
    }
  }
  .visual {
    position: relative;
    overflow: hidden;

    &.second-lottie-show {
      .first-lottie {
        display: none;
      }
    }

    .lottie-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 970px;
    }
    @include sm {
      order: 1;
      height: 60.833vw;
    }
  }
}

.tag {
  display: inline-flex;
  padding: 4px 9px;
  background-color: $gray20;
  color: $gray100;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  border-radius: 16px;
  text-transform: uppercase;
  &-red {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 8px;
    color: $red;
    border: 1px solid $red;
    font-size: 14px;
    font-weight: 500;
    border-radius: 30px;
    bottom: 60px;
    left: 60px;
    @include md {
      bottom: 32px;
      left: 24px;
      padding: 0 6px;
      height: 22px;
      font-size: 12px;
    }
    @include sm {
      top: 31px;
      left: 114px;
      padding: 0 6px;
      height: 19px;
      font-size: 10px;
      line-height: 15px;
    }
  }
  @include sm {
    font-size: 12px;
    line-height: 18px;
  }
}

ol.list-wrap {
  ol {
    counter-reset: section;
    margin-top: 24px;

    li {
      padding-left: 20px;
      text-indent: -20px;
      &::before {
        counter-increment: section;
        content: counter(section) ". ";
      }
      @include sm {
        padding-left: 16px;
        text-indent: -16px;
      }
    }
  }

  .ol-title {
    display: flex;
    align-items: center;
    gap: 12px;

    @include sm {
      gap: 8px;
    }

    strong.h4 {
      @include h4;
      @include h3-5;

      margin: inherit;
    }
  }

  .list-content {
    margin-top: 0;
  }
}

.section-about {
  .title-wrap {
    padding: 65px 40px;
    border-bottom: 1px solid $gray50;

    @include md {
      padding: 46px 40px;
    }
    @include sm {
      padding: 30px 20px;
    }

    h2 {
      @include h1-2;

      @include sm {
        white-space: pre-line;
      }
    }
  }

  .column-type {
    border-bottom: 1px solid $gray50;
  }

  .inner-title {
    align-content: center;
    padding-left: 40px;
    padding-right: 40px;

    h3 {
      @include h3;
      @include h3-5;

      & + .desc {
        margin-top: 8px;
        @include sm {
          margin-top: 5px;
        }
      }
    }

    & > * {
      width: 100%;
    }
    &::before {
      @include content;
      position: absolute;
      top: 0;
      right: -1px;
      border-left: 1px solid $gray50;
      height: 100%;
      @include sm {
        display: none;
      }
    }
    @include sm {
      width: 100%;
      padding: 28px 20px;
      border-bottom: 1px solid $gray50;
      h4 {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }

  .inner-row {
    h3 {
      margin-top: 40px;
      @include md {
        margin-top: 24px;
      }
      @include sm {
        margin-top: 0;
      }
    }
    .column-type {
      border-bottom: 0;
    }
    .inner-title {
      &::before {
        display: none;
      }
    }
  }

  .familysite-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid $gray50;
    &::before {
      @include content;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
    li {
      position: relative;
      width: 50%;
      padding: 60px 60px 10.7vw;

      @include md {
        padding: 32px 24px 98px;
      }
      @include sm {
        width: 100%;
        padding: 32px 20px;
        border-bottom: 1px solid $gray50;
        &:nth-of-type(1),
        &:nth-of-type(3) {
          &::before {
            display: none;
          }
        }
        &:last-of-type {
          border-bottom: 0;
        }
        a {
          margin-bottom: 16px;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        @include sm {
          margin-bottom: 24px;
        }

        &.disabled {
          pointer-events: none;
          cursor: initial;
        }
        span {
          position: relative;
          display: inline-block;
          &::after {
            @include content;
            position: absolute;
            bottom: -6px;
            width: 0;
            border-bottom: 3px solid $gray100;
            transition: width 300ms;
            will-change: width;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
          svg {
            width: auto;
            height: 1.7578vw;
            @include md {
              height: 24px;
            }
            @include sm {
              height: 16px;
            }
          }
          & + svg {
            @include sm {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .desc {
        color: $gray70;
      }

      &:nth-of-type(1),
      &:nth-of-type(3) {
        &::before {
          @include content;
          position: absolute;
          right: 0;
          top: 0;
          border-left: 1px solid $gray50;
          height: 100%;

          @include sm {
            display: none;
          }
        }
      }
      &:nth-of-type(1),
      &:nth-of-type(2) {
        border-bottom: 1px solid $gray50;
      }
    }
    @include sm {
      border-left: 0;
    }
  }
}

.sns-list {
  flex-wrap: nowrap !important;
  li {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.73vw 0;
    a {
      display: block;
      svg {
        width: 5.273vw;
        height: 5.273vw;
        @include md {
          width: 72px;
          height: 72px;
        }
        @include sm {
          width: 40px;
          height: 40px;
        }
      }
    }
    @include md {
      padding: 30px 0;
    }
    @include sm {
      padding: 16px 0;
    }
    &::after {
      @include content;
      position: absolute;
      top: 0;
      right: 0;
      border-left: 1px solid $gray50;
      height: 100%;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

.landing-wrap {
  .lang-wrap {
    position: relative;

    .lang-list-wrap {
      .lang-list {
        width: 185px;
        background-color: $gray30;

        z-index: 100;

        li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 52px;
          padding: 0 16px;

          &:hover {
            background-color: $gray40;
          }

          @include sm {
            height: 44px;
          }
        }

        button {
          font-size: 18px;
          line-height: 27px;
          font-weight: 500;
          text-align: left;
          color: $gray100;

          @include sm {
            font-size: 14px;
            line-height: 21px;
          }

          &.active {
            background: url("#{$rootPath}/ico_checked.svg") no-repeat right
              center;
          }
        }
      }

      .ant-popover-inner {
        box-shadow: none;
        border-radius: 0;
        background: none;
      }
      .ant-popover-inner-content {
        padding: 0;
      }
      .ant-popover-arrow {
        display: none;
      }
    }
  }
}

// voting header
// TODO: 미사용 시 삭제 필요
.header {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background: $gray10; // [24.04.01] Rewards 메뉴 추가건 (공통 스타일 변경에 따른 수정 반영)
  border-bottom: 1px solid $gray50;

  @include sm {
    padding: 0 20px 0;
  }

  &-logo-wrap {
    display: flex;
    align-items: center;
    gap: 24px;

    @include sm {
      gap: 8px;
    }
  }

  &-logo {
    $animation-time: 1s;
    $logo-padding: 92px;
    a {
      position: relative;
      display: flex;
      gap: 8px;
      align-items: center;
      padding-left: $logo-padding;
      animation: logoMove 0.6s forwards 2s;

      @include md {
        animation: none;
        padding-left: 0;
      }

      @include sm {
        animation: none;
        padding-left: 0;
      }

      .logo {
        &-typo-wemix {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 84px;
          height: 20px;
          background: url("#{$rootPath}/logo_typo_wemix.svg") no-repeat center;
          animation: logoOpacity $animation-time forwards 1.6s;
          @include md {
            display: none;
          }
          @include sm {
            display: none;
          }
        }
        &-typo-governance {
          width: 156px;
          height: 22px;
          background: url("#{$rootPath}/logo_typo_governance.svg") no-repeat
            center;
          @include sm {
            // display: none;
          }
        }
        &-symbol {
          width: 36px;
          height: 36px;
        }

        @keyframes logoOpacity {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

        @keyframes logoMove {
          0% {
            padding-left: $logo-padding;
          }
          100% {
            padding-left: 0;
          }
        }
      }
    }
  }

  &-gnb {
    position: static;
    left: 50%;
    top: 50%;
    transform: none;
    display: flex;
    gap: 0;
    border-right: none;
    background: transparent;

    li {
      padding: 0 !important;
      &.active {
        &.ant-menu-item-selected {
          background-color: transparent;
        }
        a {
          font-weight: 600;
          color: $purple2;
          text-decoration: underline;
          text-underline-offset: 23px;
          text-decoration-thickness: 2px;
        }
      }

      a {
        display: block;
        padding: 8px 16px;
        color: $gray100;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-transform: capitalize;

        &:hover {
          color: $purple2;
        }

        &:active {
          background-color: transparent;
        }
      }
    }
  }

  &-utils {
    display: flex;
    align-items: center;
    gap: 12px;
    .user-wallet {
      button {
        padding: 10px 16px;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        background-color: $gray100;
        border-radius: 44px;
      }
    }

    .lang-wrap {
      .btn-open-lang {
        padding-right: 7px;
      }
      .lang-list-wrap {
        padding-top: 5px;
        .lang-list {
          &::before {
            content: "";
            position: absolute;
            top: 5px;
            right: 11px;
            width: 0;
            height: 0;
            transform: translateY(-100%);
            border-bottom: 6px solid $gray30;
            border-top: 6px solid transparent;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
          }
        }
      }

      .btn-lang-change {
        display: inline-flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: background-color 0.2s;
        outline: none;

        &:hover {
          background-color: $gray30;
        }

        &:focus-visible,
        &:active {
          border: 1px solid $gray100;
        }

        @include sm {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  .btn-open-menu {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    color: $gray100;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
  }
  // &-mobile-gnb {
  //   width: 162px;
  //   .ant-dropdown-menu-item:hover,
  //   .ant-dropdown-menu-submenu-title:hover {
  //     background: none;
  //   }
  //   .ant-dropdown-menu {
  //     padding: 0;
  //     border-radius: 0;
  //     background: $gray30;

  //     a {
  //       padding: 12px 16px 13px;
  //       color: $gray100;
  //       font-size: 16px;
  //       font-weight: 600;
  //       line-height: 19px;
  //     }
  //   }
  // }
  .mobile-gnb {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;
    height: calc($vh * 100);
    left: 0;
    top: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    .gnb-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($gray10, 0.3);
    }

    &.show {
      transform: translateX(0);
      .gnb-inner {
        transform: translateX(0);
      }
    }
    &.leave {
      .gnb-inner {
        transform: translateX(100%);
      }
    }
    .gnb-inner {
      position: relative;
      z-index: 10;
      top: 0;
      right: 0;
      width: 100%;
      min-width: 280px;
      max-width: 392px;
      background-color: $gray30;
      transform: translateX(392px);
      transition: transform 0.3s;
      height: 100%;
      padding: 18px 0 0;
      text-align: right;
      .btn-lang-change {
        position: absolute;
        left: 20px;
      }
      .header-gnb {
        position: static;
        transform: none;
        flex-direction: column;
        text-align: left;
        gap: 0;
        li {
          margin-bottom: 0;
          margin-top: 0;
          padding: 0 20px !important;
          height: auto;
          line-height: 20px;
          &.active {
            a {
              text-decoration: none;
              color: $purple;
            }
          }
          a {
            padding: 12px 0 16px;
            font-weight: 700 !important;
            font-size: 16px;
            color: $gray100;
          }
          & + li {
            a {
              padding-top: 16px;
            }
          }
        }
      }
    }
    .btn-close {
      position: relative;
      right: 20px;
      margin-bottom: 10.5px;
      svg {
        path {
          fill: $gray100;
        }
      }
    }
  }
  .header-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 41.5px);
    justify-content: space-between;
    padding-top: 24px;
    overflow-y: auto;

    &::before {
      @include content;
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $gray50;
    }
  }
  .header-my-info {
    display: flex;
    align-items: center;
    gap: 20px;
    @include sm {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
    dl {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      gap: 17px;
      white-space: nowrap;
      @include sm {
        display: table;
      }
      div {
        display: flex;
        gap: 4px;
        @include sm {
          display: table-row;
        }
        dt {
          color: $gray60;
          @include sm {
            display: table-cell;
            text-align: left;
            padding-top: 4px;
          }
        }
        dd {
          @include sm {
            text-align: left;
            padding-top: 4px;
            padding-left: 4px;
            display: table-cell;
            color: $gray40;
          }
          color: $gray80;
        }
        & + div {
          position: relative;
          &::before {
            @include content;
            position: absolute;
            left: -8px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 11px;
            background-color: #737373;
            @include sm {
              display: none;
            }
          }
        }
      }
    }
    @include sm {
      // .button-wrap.outline {
      //   color: $gray10;
      //   border-color: $gray10;
      // }
    }

    // [24.04.11] 로그인 버튼 내 아이콘 컬러 변경
    .button-wrap.outline {
      img {
        margin-right: 4px;
        filter: brightness(0) saturate(100%) invert(100%);
      }
    }

    .btns-wrap {
      display: flex;
      margin-top: 0;
      gap: 12px;

      button {
        font-feature-settings: "calt" off;
      }
    }
  }
}

.modal-open {
  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
    transition-duration: 0 !important;
    transform: none !important;
  }
}

.footer {
  padding: 48px 0;
  background: $gray20;
  border-top: 1px solid $gray50;
  &.detail {
    background: $gray100;
    border-top: 0;
    .footer-top {
      .footer-logo {
        // opacity: 0.3;
        svg {
          path {
            fill: $gray10;
          }
        }
      }
      .footer-familysite {
        color: $gray30;
        a {
          color: $gray50;
        }
      }
    }
    .footer-bottom {
      border-top-color: $gray80;
      .footer-link-wrap {
        .footer-sns {
          svg {
            width: 32px;
            height: 32px;
            path {
              fill: $gray10;
            }
          }
        }
      }
    }
  }
  @include sm {
    padding: 31px 0;
  }
  &-inner {
    padding: 0 40px;
    @include sm {
      padding: 0 20px;
    }
  }
  &-top {
    @include sm {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .footer-logo {
      @include sm {
        svg {
          width: 157px;
          height: auto;
        }
      }
    }

    .footer-familysite {
      margin-top: 32px;
      color: $gray100;
      &-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $gray50;
      }
      &-list {
        display: flex;
        gap: 20px;
        margin-top: 8px;

        a {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          &:hover {
            color: $gray100;
          }
        }

        .coming-soon {
          display: inline-flex;
          align-items: center;
          gap: 4px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;

          &-tag {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 82px;
            color: $red;
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            border: 1px solid $red;
            border-radius: 10px;
          }
        }
      }
    }

    .footer-familysite-mo {
      .familysite-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 140px;
        padding: 8px;
        color: $gray70;
        border: 1px solid $gray40;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;

        &.active {
          i {
            transform: rotate(180deg);
          }
        }

        i {
          display: inherit;
          transition: transform 0.2s;

          svg {
            width: 16px;
            height: auto;
          }
        }
      }
      .familysite-list {
        width: 185px;
        background: $gray30;
        color: $gray100;
        a,
        div {
          display: block;
          padding: 11px 16px 12px;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;

          &:hover {
            color: $gray100;
          }
        }

        .coming-soon {
          display: flex;
          align-items: center;
          gap: 4px;

          &-tag {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 82px;
            color: $red;
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            border: 1px solid $red;
            border-radius: 10px;
          }
        }
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    margin-top: 48px;
    border-top: 1px solid $gray50;

    @include sm {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-top: 36px;
      padding-top: 0;
      border: none;
    }

    .footer-contact {
      @include sm {
        margin-top: 16px;
      }

      & > ul {
        display: flex;
        align-items: center;
        gap: 24px;
        color: $gray100;

        @include sm {
          gap: 16px;
        }

        a {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: $gray50;
          &:hover {
            color: $gray100;
          }
        }
      }

      .footer-copy {
        font-size: 10px;
        line-height: 15px;
        color: $gray50;

        @include sm {
          margin-top: 8px;
        }
      }
    }

    .footer-sns {
      display: flex;
      align-items: center;
      gap: 20px;

      @include sm {
        gap: 4px;
      }

      a {
        svg {
          opacity: 0.2;
          transition: opacity 0.3s;
          width: 32px;
          height: 32px;
        }

        &:hover {
          svg {
            opacity: 1;
          }
        }
      }
    }

    .lang-wrap {
      height: 24px;

      @include sm {
        height: 20px;
      }
      .lang-list-wrap {
        padding-bottom: 5px;
        .lang-list {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            transform: translateX(-50%);
            border-bottom: 6px solid transparent;
            border-top: 6px solid $gray30;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;

            @include sm {
              left: auto;
              right: 5px;
              transform: none;
            }
          }
        }
      }

      .btn-lang-change {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        transition: background-color 0.2s;

        @include sm {
          width: 20px;
          height: 20px;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        &:hover {
          background-color: $gray30;

          svg {
            path {
              fill: $gray100;
            }
          }
        }

        &:active {
          border: 1px solid $gray100;
        }

        svg {
          width: 20px;
          height: 20px;
          path {
            transition: fill 0.2s;
          }
        }
      }
    }
  }
}

.governance-container {
  display: flex;
  height: calc(100vh - 60px);
  height: calc(($vh * 100) - 60px);
  min-height: 640px;
  background: $gray20;
  color: $gray100;

  @include md {
    min-height: 580px;
  }

  @include sm {
    display: block;
    padding: 32px 20px 24px;
    height: auto;
    min-height: calc(100vh - 60px);
    min-height: calc(($vh * 100) - 60px);
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

    @include sm {
      width: 100%;
    }
  }

  .text-block {
    padding: 0 40px;
    border-right: 1px solid $gray50;

    @include sm {
      position: relative;
      border-right: none;
      padding: 0;
    }

    .governance-title {
      @include fontVwSize(88, 96.8);
      font-weight: 500;
      white-space: pre-line;

      @include md {
        font-size: 48px;
        line-height: 53px;
      }

      @include sm {
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
      }
    }

    .governance-desc {
      max-width: 541px;
      margin-top: 12px;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;

      @include md {
        max-width: none;
        font-size: 16px;
        line-height: 24px;
      }

      @include sm {
        max-width: none;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .button-wrap {
      margin-top: 24px;

      @include sm {
        position: fixed;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        z-index: 10;

        @include sm {
          margin-top: 0;
        }
      }
      .btn-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 238px;
        height: 48px;
        padding: 0 16px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        border: 1px solid $gray100;
        background: $gray20;

        &:hover {
          color: $gray100;
        }

        @include sm {
          width: 100%;
        }
      }
    }
  }
  .video-block {
    align-items: center;

    .video-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      svg {
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }
    }
  }
}
.section-40wonders {
  border-bottom: 1px solid $gray50;
  .wonders-section {
    .title-wrap {
      padding: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $gray50;
      @include md {
        padding: 32px 40px;
        // display: block;
      }
      @include sm {
        padding: 20px 20px 28px;
        display: block;
      }
      h3 {
        @include h2;
        // font-size: 40px;
        // line-height: 1.3;
        // font-weight: 400;
        margin: 0;
        @include md {
          // font-weight: 500;
        }
        @include sm {
          margin-bottom: 12px;
          font-size: 32px;
          line-height: 39px;
          font-weight: 600;
        }
      }
      .wonder-list {
        display: flex;
        & > div {
          & + div {
            margin-left: 8px;
          }
          span {
            &:not(:first-child) {
              margin-left: 8px;
            }
            font-feature-settings: "tnum" on, "lnum" on, "cv13" on;
            font-size: 14px;
            font-weight: 400;
            color: $gray40;
            &.active {
              color: $gray100;
            }
            @include md {
              font-size: 12px;
            }
            @include sm {
              margin-left: 0 !important;
              font-size: 12px;
            }
          }
        }

        @include md {
          max-width: 50%;
          flex-wrap: wrap;
          justify-content: flex-end;
        }

        @include sm {
          display: block;
          & > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            & + div {
              margin-left: 0;
              margin-top: 12px;
            }
          }
        }
      }
    }
    .swiper {
      position: relative;
      font-size: 100%;
      .wonders-swiper-inner {
        display: flex;
        justify-content: center;
        img {
          width: 100%;
        }
      }
      .wonders-number {
        width: 100%;
        max-width: 240px;
        svg {
          width: 100%;
          height: auto;
        }
      }
      .swiper-slide {
        width: auto;
        height: auto;
        & + .swiper-slide {
          position: relative;
          &::before {
            @include content;
            position: absolute;
            width: 1px;
            height: 100%;
            left: 0;
            top: 0;
            background-color: $gray50;
          }
        }
        @include sm {
          padding-bottom: 40px;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        z-index: 20;
        width: 40px;
        height: 40px;
        top: auto;
        bottom: 0;
        right: 0;
        left: auto;
        border-top: 1px solid $gray50;
        border-left: 1px solid $gray50;
        opacity: 1 !important;
        background-color: $gray20;
        @include sm {
          width: 50%;
          bottom: 0;
          margin-top: -40px;
        }
        &.swiper-button-disabled {
          pointer-events: visible;
          &::after {
            opacity: 0.2;
          }
        }
        &::after {
          position: relative;
          content: "";
          width: 40px;
          height: 40px;
          bottom: 0;
          right: 0;
          background-image: url("/img/ico_swiper_arrow.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .swiper-button-prev {
        justify-content: flex-start;
        right: 40px;
        @include sm {
          border-left: 0;
          right: auto;
          left: 0;
          &::after {
            left: 8px;
          }
        }
      }
      .swiper-button-next {
        justify-content: flex-end;
        &::after {
          transform: rotate(180deg);
          @include sm {
            right: 8px;
          }
        }
      }
    }
  }
}

// .wonders-header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   height: 60px;
//   padding: 0 20px;
//   background: $gray20;
//   border-bottom: 1px solid $gray50;
//   border: 1px solid red;

//   @include templateSm {
//     padding: 0 16px;
//   }

//   &-logo-wrap {
//     display: flex;
//     align-items: center;
//     gap: 32px;

//     @include templateSm {
//       gap: 8px;
//     }
//   }

//   &-logo {
//     $animation-time: 1s;
//     $logo-padding: 92px;
//     a {
//       position: relative;
//       display: flex;
//       gap: 8px;
//       align-items: center;
//       padding-left: $logo-padding;
//       animation: logoMove 0.6s forwards 2s;

//       @include templateSm {
//         animation: none;
//         padding-left: 0;
//       }

//       .logo {
//         &-typo-wemix {
//           position: absolute;
//           top: 50%;
//           left: 0;
//           transform: translateY(-50%);
//           width: 84px;
//           height: 20px;
//           background: url("#{$rootPath}/logo_typo_wemix.svg") no-repeat center;
//           animation: logoOpacity $animation-time forwards 1.6s;
//           @include templateSm {
//             display: none;
//           }
//         }
//         &-typo-governance {
//           width: 156px;
//           height: 22px;
//           background: url("#{$rootPath}/logo_typo_governance.svg") no-repeat
//             center;
//           @include templateSm {
//             display: none;
//           }
//         }
//         &-symbol {
//           width: 36px;
//           height: 36px;
//         }

//         @keyframes logoOpacity {
//           0% {
//             opacity: 1;
//           }
//           100% {
//             opacity: 0;
//           }
//         }

//         @keyframes logoMove {
//           0% {
//             padding-left: $logo-padding;
//           }
//           100% {
//             padding-left: 0;
//           }
//         }
//       }
//     }
//   }

//   &-gnb {
//     display: flex;
//     border-right: none;
//     background: transparent;

//     &.connect {
//       li.ant-menu-item {
//         &:nth-child(2) {
//           display: block;
//         }
//       }
//     }

//     li.ant-menu-item {
//       margin-top: 0 !important;
//       margin-bottom: 0 !important;
//       line-height: normal;
//       height: auto;
//       padding: 0;

//       &:active,
//       &.ant-menu-item-selected {
//         background-color: transparent;
//       }

//       & + li {
//         margin-left: 24px;
//       }

//       &:nth-child(2) {
//         display: none;
//       }

//       &.active {
//         a {
//           color: $gray100;
//           text-decoration: underline;
//           text-underline-offset: 3px;

//           @include templateSm {
//             font-weight: 600;
//             font-size: 18px;
//             line-height: 27px;
//             text-decoration: none;

//             &:hover,
//             &:focus,
//             &:active {
//               text-decoration: none;
//             }
//           }
//         }
//       }

//       a {
//         display: block;
//         color: $gray70;
//         font-size: 15px;
//         line-height: 23px;

//         &::before,
//         &::after {
//           display: none;
//         }

//         &:hover,
//         &:focus,
//         &:active {
//           color: $gray100;
//           text-decoration: underline;
//           text-underline-offset: 3px;
//         }
//       }
//     }
//   }

//   &-utils {
//     display: flex;
//     align-items: center;
//     gap: 16px;

//     .user-wallet {
//       display: flex;
//       align-items: center;

//       .btn-wallet-join {
//         display: flex;
//         align-items: center;
//         padding: 0 16px;
//         height: 36px;
//         font-size: 14px;
//         font-weight: 600;
//         line-height: 21px;
//         background-color: $gray100;
//         border-radius: 44px;
//         transition: background-color 0.3s;
//         outline: none;

//         &:hover,
//         &:active,
//         &:focus {
//           background: $gray80;
//         }

//         svg {
//           margin-right: 4px;
//           flex-shrink: 0;
//         }
//       }

//       .btn-wallet-staking {
//         padding: 0 16px;
//         height: 36px;
//         font-size: 14px;
//         font-weight: 600;
//         line-height: 21px;
//         border: 1px solid $gray100;
//         color: $gray100;
//         border-radius: 44px;
//         background: transparent;
//         transition: background-color 0.3s;
//         outline: none;
//         margin-right: 6px;

//         &:hover,
//         &:active,
//         &:focus {
//           background: $gray30;
//         }

//         @include templateSm {
//           display: none;
//         }
//       }

//       .btn-wallet-account {
//         display: flex;
//         align-items: center;
//         padding: 0 16px 0 16px;
//         height: 36px;
//         font-size: 14px;
//         font-weight: 600;
//         line-height: 21px;
//         border: 1px solid $gray100;
//         color: $gray100;
//         border-radius: 44px;
//         background: transparent;
//         transition: background-color 0.3s;
//         outline: none;

//         &:hover,
//         &:active,
//         &:focus {
//           background: $gray30;
//         }

//         svg {
//           margin-right: 4px;
//         }
//       }
//     }

//     .staking-info {
//       display: flex;
//       align-items: center;

//       @include templateSm {
//         display: none;
//       }

//       dl {
//         display: flex;
//         align-items: center;

//         dt {
//           color: $gray60;
//           font-size: 14px;
//           line-height: 1.2;
//           font-weight: 500;
//         }

//         dd {
//           margin-left: 8px;
//           color: $gray100;
//           font-size: 14px;
//           line-height: 21px;
//           font-weight: 600;
//         }
//         & + dl {
//           position: relative;
//           margin-left: 12px;
//           padding-left: 12px;

//           &::before {
//             content: "";
//             position: absolute;
//             top: 50%;
//             left: 0;
//             width: 1px;
//             height: 11px;
//             background: $gray50;
//             transform: translateY(-50%);
//           }
//         }
//       }
//     }
//   }

//   .btn-open-menu {
//     display: inline-flex;
//     align-items: center;
//     gap: 4px;
//     color: $gray100;
//     font-size: 18px;
//     line-height: 27px;
//     font-weight: 600;

//     svg {
//       width: 24px;
//       height: 24px;
//       padding: 4px;
//     }
//   }

//   &-mobile-gnb {
//     width: 162px;
//     .ant-dropdown-menu-item:hover,
//     .ant-dropdown-menu-submenu-title:hover {
//       background: none;
//     }
//     .ant-dropdown-menu {
//       padding: 0;
//       border-radius: 0;
//       background: $gray30;

//       li.ant-dropdown-menu-item {
//         padding: 0;
//       }

//       a,
//       button {
//         display: block;
//         padding: 12px 16px 13px;
//         color: $gray100;
//         font-size: 16px;
//         font-weight: 600;
//         line-height: 19px;
//         text-align: left;
//         margin: 0;
//         width: 100%;
//         transition: background-color 0.3s;

//         &:hover {
//           background: $gray40;
//         }
//       }
//     }
//   }
// }

.wonders-footer {
  background-color: $gray100;
  .wonders-footer-inner {
    padding: 48px 16px;
    max-width: 1152px;
    margin: 0 auto;
    @include templateSm {
      padding: 32px 16px;
    }
    .wonders-footer-top {
      @include templateSm {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .wonders-footer-logo {
        svg {
          width: 202px;
          height: auto;
        }
        svg path {
          fill: $gray80;
        }
        @include templateSm {
          svg {
            width: 157px;
            height: auto;
          }
        }
      }
      .wonders-footer-familysite {
        margin-top: 32px;
        color: $gray50;
        &-title {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: $gray60;
        }
        &-list {
          display: flex;
          gap: 20px;
          margin-top: 8px;

          a {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            &:hover {
              color: $gray50;
            }
          }
        }
      }
    }

    .wonders-footer-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid $gray80;
      border-color: $gray80;
      margin-top: 46px;
      padding-top: 23px;

      @include templateSm {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-top: 36px;
        padding-top: 0;
        border: none;
      }
      .wonders-footer-contact {
        @include templateSm {
          margin-top: 16px;
        }

        & > ul {
          display: flex;
          align-items: center;
          gap: 24px;
          color: $gray100;

          @include templateSm {
            gap: 16px;
          }

          a {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: $gray70;
            transition: color 0.3s ease;

            &:hover {
              color: $gray50;
            }
          }
        }
      }
      .wonders-footer-copy {
        margin-top: 3px;
        color: $gray70;
        font-size: 10px;
        line-height: 15px;
        @include templateSm {
          margin-top: 8px;
        }
      }
      .wonders-footer-sns {
        display: flex;
        align-items: center;
        gap: 20px;
        @include templateSm {
          gap: 4px;
        }
      }

      .wonders-footer-link-wrap {
        li svg {
          width: 32px;
          height: 32px;

          path {
            fill: $gray70;
            transition: fill 0.3s ease;
          }
        }

        li:hover svg path {
          fill: $gray50;
        }
      }
    }
    .wonders-footer-familysite-mo {
      .wonders-familysite-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 140px;
        padding: 8px;
        color: $gray70;
        border: 1px solid $gray80;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;

        &.active {
          i {
            transform: rotate(180deg);
          }
          color: $gray50;
          svg path {
            fill: $gray50;
          }
        }

        i {
          display: inherit;
          transition: transform 0.2s;

          svg {
            width: 16px;
            height: auto;
          }
        }
      }
      .wonders-familysite-list {
        width: 185px;
        background: $gray30;
        color: $gray100;

        a,
        div {
          display: block;
          padding: 11px 16px 12px;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;

          &:hover {
            color: $gray100;
          }
        }
      }
    }
  }
}

.loading-wrapper {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
  opacity: 1;
  transition: all ease 0.5s 0s;
  visibility: visible;
  .loading-img {
    width: 70px;
    height: 70px;
    background: url("#{$publicPath}/assets/images/logo_wemix_governance_symbol.png")
      no-repeat center center;
    background-size: 70px;
    animation: loading 10s infinite linear;
    transform-origin: center;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tx-loading {
  opacity: 0.8 !important;
  z-index: 1000 !important;
}

.button-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.md {
    min-width: 160px;
    height: 52px;
    font-size: 16px;
  }
  &.sm {
    padding: 10px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }
  &:disabled {
    opacity: 0.3;
  }
  svg {
    margin-right: 5px;

    path {
      fill: $gray100;
    }
  }

  @include sm {
    height: 40px;
  }
  &.bg {
    background-color: $deepBlue;
    color: $gray100;
    transition: background-color 0.3s;
    &:hover {
      background-color: #4f4fe3;
    }
  }
  &.bg-white {
    background-color: $gray100;
    color: $gray20;
    transition: background-color 0.3s;
    &:hover {
      background-color: $gray90;
    }
  }
  &.prefix {
    &::before {
      @include content;
      content: "+";
      padding-right: 3px;
    }
  }
  &.outline {
    border: 1px solid $gray60;
    color: $gray100;
    &.sm {
      border-radius: 20px;
      transition: background-color 0.3s;
      &:hover {
        background-color: rgba($gray100, 0.2);
      }
    }
  }
}

// 약관페이지
.policy-wrap {
  padding: 40px 0 70px;
  background-color: white;
}

.ant-tooltip {
  white-space: pre-line;
  .ant-tooltip-inner {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    span {
      display: block;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $gray40;
    }
    strong {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $gray10;
    }
  }
  .ant-tooltip-arrow {
    &::before {
      background-color: #fff;
    }
  }
}

/* loading modal */
.loading-modal {
  .ant-modal-body {
    padding: 40px 32px;
  }
  .loading-modal-wrap {
    display: block;
    text-align: center;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      color: $gray20;
    }

    .top-content-wrap {
      p {
        margin-top: 2px;
      }
    }

    .loading-msg {
      margin-top: 20px;
    }

    // loading spinner style
    .loading-spinner {
      display: inline-block;
      position: relative;
      font-size: 56px;
      animation: 2s rotate infinite linear;
      width: 64px;
      height: 64px;
    }

    .loading-spinner circle {
      fill: transparent;
      stroke: #323cff;
      stroke-width: 4px;
      stroke-linecap: round;
      stroke-dasharray: 2.3525em 0.4705em;
      // animation: 1.5s strokeDashArray infinite linear;
      transform: rotate(0deg);
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    @keyframes strokeDashArray {
      from {
        stroke-dasharray: 2.3525em 0.4705em;
        stroke-dashoffset: 0;
      }

      10% {
        stroke-dasharray: 2.3525em 0.4705em;
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dasharray: 0.001em 2.825em;
        stroke-dashoffset: -2.826em;
      }

      60% {
        stroke-dasharray: 0.001em 2.825em;
      }

      to {
        stroke-dasharray: 2.3525em 0.4705em;
        stroke-dashoffset: -2.826em;
      }
    }
  }
}
