@import "./variables";

// ~ 359
@mixin xsm {
  @media (max-width: #{$breakpointXsm - 1px}) {
    @content;
  }
}

// ~ 1120
@mixin sm {
  @media (max-width: #{$breakpointSm - 1px}) {
    @content;
  }
}

// 1120 ~ 1440
@mixin md {
  @media (min-width: #{$breakpointSm}) and (max-width: #{$breakpointMd - 1px}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$breakpointMd}) {
    @content;
  }
}

// ~ 1151
@mixin templateSm {
  @media (max-width: 1151px) {
    @content;
  }
}

@mixin content($display: block) {
  display: $display;
  content: "";
  clear: both;
}

@mixin fontVwSize($size, $line-height) {
  font-size: #{calc($size / 25.6)}vw;
  line-height: #{calc($line-height / 25.6)}vw;
}

@mixin maxWidth {
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  @include sm {
    padding: 0 20px;
  }
}

@mixin statusBg($statusColor) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  height: 36px;
  border-radius: 36px;
  width: 104px;
  color: $statusColor;
  background-color: rgba($statusColor, 0.1);
  text-transform: capitalize;
  span {
    font-weight: 700;
    font-size: 14px;
    padding-right: 4px;
  }
  @include sm {
    order: 1;
    height: 28px;
    width: 100%;
    border-radius: 0;
    justify-content: flex-start;
    padding-left: 7px;
  }
}
@mixin statusBgDark($statusColor) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  height: 30px;
  border-radius: 36px;
  padding: 0 14px;
  color: $statusColor;
  border: 1px solid $statusColor;
  text-transform: capitalize;
  svg {
    margin-left: -4px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
  }

  @include sm {
    padding: 0 9px;
    height: 26px;
    svg {
      margin-left: -2px;
    }
    span {
      font-weight: 500;
      font-size: 12px;
    }
  }
}

@mixin errMsgState {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $critical;
  margin-top: 6px;
  display: block;
}

// pc, tablet용
@mixin h1 {
  max-width: 39.453vw;
  font-weight: 500;
  white-space: pre-line;
  @include fontVwSize(88, 106);

  @include md {
    font-size: 48px;
    line-height: 58px;
    max-width: none;
  }
  @include sm {
    max-width: none;
    font-size: 32px;
    line-height: 39px;
  }
}

@mixin h2 {
  position: relative;
  font-weight: 500;
  margin: 0 auto;
  @include fontVwSize(72, 86);

  @include md {
    font-size: 40px;
    line-height: 48px;
  }

  sup {
    position: relative;
    top: -1.33vw;
    @include fontVwSize(50, 98.8);
    @include md {
      top: -15px;
      font-size: 30px;
      line-height: 58px;
    }
    @include sm {
      top: -15px;
      font-size: 16px;
      line-height: 42px;
    }
  }
}
@mixin h3 {
  font-weight: 600;
  margin: 0 auto;
  @include fontVwSize(56, 67);

  @include md {
    font-size: 32px;
    line-height: 38px;
  }
}

@mixin h4 {
  font-weight: 600;
  margin: 0 auto;
  @include fontVwSize(44, 62);

  @include md {
    font-size: 24px;
    line-height: 34px;
  }

  sup {
    position: relative;
    top: -0.7em;
    @include fontVwSize(30, 30);

    @include md {
      top: -0.5em;
      font-size: 1;
    }
    @include sm {
      top: -0.5em;
      font-size: 16px;
      line-height: 1;
    }
  }
}

@mixin body-1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;

  @include md {
    font-size: 16px;
  }
  @include sm {
    font-size: 14px;
  }
}

@mixin body-2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;

  @include md {
    font-size: 14px;
  }
  @include sm {
    font-size: 14px;
  }
}

@mixin h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  @include sm {
    font-size: 16px;
  }
}

// mobile 용
@mixin h1-2 {
  @include sm {
    font-size: 32px;
    line-height: 39px;
    font-weight: 600;
  }
}

@mixin h3-5 {
  @include sm {
    font-size: 24px;
    line-height: 34px;
  }
}

// 말줄임
@mixin ellipsis($type: 0) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;

  @if $type == 0 {
    white-space: nowrap;
    word-wrap: normal;
    word-wrap: break-word;
  } @else {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: $type;
    /* stylelint-disable-next-line order/properties-order */
    -webkit-box-orient: vertical;
  }
}

@keyframes jump-arrow {
  0%,
  20%,
  55%,
  80%,
  100% {
    transform: translate3d(-50%, 0, 0);
  }

  40% {
    transform: translate3d(-50%, -10px, 0);
  }

  70% {
    transform: translate3d(-50%, -5px, 0);
  }

  90% {
    transform: translate3d(-50%, -3px, 0);
  }
}

@mixin transition-default($properties...) {
  $transition: ();
  $will-change: ();

  @each $property in $properties {
    $transition: append(
      $transition,
      $property 0.3s ease-in-out,
      $separator: comma
    );
    $will-change: append($will-change, $property, $separator: comma);
  }

  transition: $transition;
  will-change: $will-change;
}

@mixin arrow-left {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  margin-bottom: 30px;
  transform: rotate(-45deg);
  font-size: 0;
}

@mixin arrow-left-before($color: #ffffff) {
  content: "";
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  border: 2px solid $color;
  border-right: 0;
  border-bottom: 0;
}

@mixin arrow-left-after($color: #ffffff) {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 18px;
  height: 2px;
  background: $color;
  transform-origin: 0 100%;
  transform: rotate(45deg);
}
