@import "./mixin";
@function to-rgb($color) {
  @return #{red($color) + ", " + green($color) + ", " + blue($color)};
}

/** rgba 컬러 사용
  * 사용 예시
  * border-top: 1px solid theme-rgba($theme-base-color, $line-subtler);
*/
@function theme-rgba($key, $opacity, $map: $theme-dark) {
  @return rgba(var($key), $opacity);
}

/** 일반 컬러 사용
  * 사용 예시
  * color: theme-color($text-normal);
*/
@function theme-color($key, $map: $theme-dark) {
  @return rgb(var($key));
}

$core-scale-gray-0: #000;
$core-scale-gray-3: #070709;
$core-scale-gray-5: #111113;
$core-scale-gray-10: #1b1b1d;
$core-scale-gray-20: #313033;
$core-scale-gray-30: #474649;
$core-scale-gray-40: #5f5e61;
$core-scale-gray-50: #78767a;
$core-scale-gray-60: #929094;
$core-scale-gray-70: #aeacb0;
$core-scale-gray-80: #c7c6cb;
$core-scale-gray-90: #e4e2e8;
$core-scale-gray-95: #f2f0f6;
$core-scale-gray-97: #f7f5fb;
$core-scale-gray-100: #fff;
$core-scale-op-black-7: rgba(0 0 0 / 7%);
$core-scale-op-black-12: rgba(0 0 0 / 12%);
$core-scale-op-black-16: rgba(0 0 0 / 16%);
$core-scale-op-black-20: rgba(0 0 0 / 20%);
$core-scale-op-black-30: rgba(0 0 0 / 30%);
$core-scale-op-black-40: rgba(0 0 0 / 40%);
$core-scale-op-black-50: rgba(0 0 0 / 50%);
$core-scale-op-black-70: rgba(0 0 0 / 70%);
$core-scale-op-black-100: #000;
$core-scale-op-white-7: rgba(255 255 255 / 7%);
$core-scale-op-white-12: rgba(255 255 255 / 12%);
$core-scale-op-white-16: rgba(255 255 255 / 16%);
$core-scale-op-white-20: rgba(255 255 255 / 20%);
$core-scale-op-white-30: rgba(255 255 255 / 30%);
$core-scale-op-white-40: rgba(255 255 255 / 40%);
$core-scale-op-white-50: rgba(255 255 255 / 50%);
$core-scale-op-white-70: rgba(255 255 255 / 70%);
$core-scale-op-white-100: #fff;

// 색상 변수 처리
$theme-base-color: --theme-base-color;
$theme-bg-color: --theme-bg-color;
$core-line-stronger: --core-line-stronger;
$core-line-strong: --core-line-strong;
$core-line-normal: --core-line-normal;
$core-line-subtle: --core-line-subtle;
$core-line-subtler: --core-line-subtler;
$core-line-subtlest: --core-line-subtlest;
$core-text-strongest: --core-text-strongest;
$core-text-strong: --core-text-strong;
$core-text-normal: --core-text-normal;
$core-text-subtle: --core-text-subtle;
$core-text-subtler: --core-text-subtler;
$core-bg-bw: --core-bg-bw;
$core-bg-subtle: --core-bg-subtle;
$core-bg-subtler: --core-bg-subtler;
$core-bg-subtlest: --core-bg-subtlest;
$core-bg-overlay: --core-bg-overlay;
$core-state-hover: --core-state-hover;
$core-state-pressed: --core-state-pressed;

// opacity 수치
$line-stronger: 0.4;
$line-strong: 0.3;
$line-normal: 0.2;
$line-subtle: 0.16;
$line-subtler: 0.12;
$line-subtlest: 0.07;
$state-hover: 0.07;
$state-pressed: 0.12;

// dark (night)
$theme-dark: (
  $theme-bg-color: to-rgb(#1b1b1d),
  // 다국어 선택 드롭다운 옵션, certik 등 배너 배경색
  $theme-base-color: to-rgb(#fff),
  // icon, border 등 opacity가 들어가는 베이스 색상
  $core-line-stronger: rgba(0 0 0 / 40%),
  $core-line-strong: rgba(0 0 0 / 30%),
  $core-line-normal: rgba(0 0 0 / 20%),
  $core-line-subtle: rgba(0 0 0 / 16%),
  $core-line-subtler: rgba(0 0 0 / 12%),
  $core-line-subtlest: rgba(0 0 0 / 7%),
  $core-text-strongest: to-rgb(#fff),
  $core-text-strong: to-rgb(#e4e2e8),
  // 링크 텍스트 active/focus, 다국어 변경 옵션 텍스트/아이콘 색상
  $core-text-normal: to-rgb(#aeacb0),
  // 링크 텍스트 hover 색상
  $core-text-subtle: to-rgb(#78767a),
  // 링크 텍스트, 다국어 변경 버튼 텍스트 색상
  $core-text-subtler: to-rgb(#5f5e61),
  // copyright text, 다국어 변경 버튼 화살표 색상
  $core-bg-bw: to-rgb(#000),
  $core-bg-subtle: to-rgb(#1b1b1d),
  // footer의 배경색
  $core-bg-subtler: to-rgb(#111113),
  $core-bg-subtlest: to-rgb(#070709),
  $core-bg-overlay: to-rgb(#1b1b1d),
  $core-state-hover: rgba(255 255 255 / 7%),
  $core-state-pressed: rgba(255 255 255 / 12%),
);

// light (day)
$theme-light: (
  $theme-bg-color: to-rgb(#fff),
  // 다국어 선택 드롭다운 옵션, certik 등 배너 배경색
  $theme-base-color: to-rgb(#000),
  // icon, border 등 opacity가 들어가는 베이스 색상
  $core-line-stronger: rgba(255 255 255 / 40%),
  $core-line-strong: rgba(255 255 255 / 30%),
  $core-line-normal: rgba(255 255 255 / 20%),
  $core-line-subtle: rgba(255 255 255 / 16%),
  $core-line-subtler: rgba(255 255 255 / 12%),
  $core-line-subtlest: rgba(255 255 255 / 7%),
  $core-text-strongest: to-rgb(#000),
  $core-text-strong: to-rgb(#1b1b1d),
  // 링크 텍스트 active/focus, 다국어 변경 옵션 텍스트/아이콘 색상
  $core-text-normal: to-rgb(#474649),
  // 링크 텍스트 hover 색상
  $core-text-subtle: to-rgb(#78767a),
  // 링크 텍스트, 다국어 변경 버튼 텍스트 색상
  $core-text-subtler: to-rgb(#929094),
  // copyright text, 다국어 변경 버튼 화살표 색상
  $core-bg-bw: to-rgb(#fff),
  $core-bg-subtle: to-rgb(#fff),
  // footer의 배경색
  $core-bg-subtler: to-rgb(#f2f0f6),
  $core-bg-subtlest: to-rgb(#f7f5fb),
  $core-bg-overlay: to-rgb(#fff),
  $core-state-hover: rgba(0 0 0 / 7%),
  $core-state-pressed: rgba(0 0 0 / 12%),
);

/** theme scss 변수를 css 변수로 변환
 * 사용 예시
 // dark mode
  &.dark {
    @include theme-map($theme-dark);
  }
  // light mode
  &.light {
    @include theme-map($theme-light);
  }
 */
@mixin theme-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

// 폰트
@mixin core-font-compact-s {
  font-family: "WEMIX Pretendard", sans-serif;
  font-feature-settings: "cv13" on, "calt" off;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
}

@mixin core-font-compact-xs {
  font-feature-settings: "cv13" on, "calt" off;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
}

// 심볼과 텍스트 모두에서 사용되는 폰트 사이즈여서 family 선언 안함
@mixin core-font-compact-xxs {
  font-feature-settings: "cv13" on, "calt" off;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
}

// 코어 전용 믹스인
@mixin core-transition-default($properties...) {
  $transition: ();
  $will-change: ();

  @each $property in $properties {
    /* stylelint-disable-next-line scss/no-global-function-names */
    $transition: append(
      $transition,
      $property 0.3s ease-in-out,
      $separator: comma
    );
    /* stylelint-disable-next-line scss/no-global-function-names */
    $will-change: append($will-change, $property, $separator: comma);
  }

  transition: $transition;
  will-change: $will-change;
}

@mixin core-content {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

/**
  * scroll bar 컴스텀 스타일 속성 정의 (기본값은 플랫폼 메뉴 스크롤바 스타일 기준)
  * $width: 스크롤바 너비
  * $thumb: 스크롤 핸들 색상
  * $thumb-size: 스크롤 핸들 두께
  * $thumb-radius: 스크롤 핸들 radius
  * $track: 스크롤 트랙 색상
  * $track-margin-bottom: 스크롤 트랙 하단 마진
  * $track-margin-top: 스크롤 트랙 상단 마진
  * $scroll-bar-radius: 스크롤바 우상단, 우하단 둥근 모서리 radius
*/
@mixin core-scroll(
  $width: 8px,
  $thumb: $core-scale-gray-70,
  $thumb-size: 2px,
  $thumb-radius: 4px,
  $track: $core-bg-subtler,
  $track-margin-bottom: 6px,
  $track-margin-top: 6px,
  $scroll-bar-radius: 12px
) {
  $scrollbar-thumb-bottom-margin: $track-margin-bottom;
  $scrollbar-thumb-top-margin: $track-margin-top;
  $scrollbar-thumb-width: $width;
  $scrollbar-thumb-color: $thumb;
  $scrollbar-track-color: $track;

  /* Chrome, Opera */
  &::-webkit-scrollbar {
    position: absolute;
    width: $scrollbar-thumb-width;
    height: $scrollbar-thumb-width;
    border-radius: 0 $scroll-bar-radius $scroll-bar-radius 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0 $scroll-bar-radius $scroll-bar-radius 0;
    margin-top: $scrollbar-thumb-bottom-margin;
    margin-bottom: $scrollbar-thumb-top-margin;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: $thumb-size solid transparent;
    border-radius: $thumb-radius;
    background: $scrollbar-thumb-color;
    background-clip: padding-box;
  }

  // NOTE: scrollbar-width 속성이 있을 경우 커스텀 스타일이 적용되지 않아 속성 삭제
  // scrollbar-width: thin;
}

// 코어 라이브러리 반응형 믹스인 - 각 플랫폼별 믹스인을 BP범위에 맞는 곳에 인클루드 해주세요
@mixin core-desktop {
  // 데스크탑 해상도
  @include lg {
    @content;
  }
}

@mixin core-tablet {
  // 태블릿 해상도
  @include md {
    @content;
  }
}

@mixin core-mobile {
  // 모바일 해상도
  @include sm {
    @content;
  }
}

@mixin core-mobile-fold {
  // 280 대응 해상도
  @include xsm {
    @content;
  }
}
