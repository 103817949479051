.body-lock {
  overflow: hidden;
}
.voting-chart-mini {
  display: inline-flex;
  &.light {
    gap: 2px;
    width: 64px;
    height: 4px;
    background-color: #cacaca;
    @include sm {
      width: 100%;
    }
  }
  &.dark {
    width: 40px;
    height: 3px;
    border-radius: 3px;
    overflow: hidden;
    background-color: $gray70;
  }
  .graph-positive {
    background-color: $positive;
  }
  .graph-critical {
    background-color: $critical;
  }
}
.icon-with-text-wrap {
  display: inline-flex;
  gap: 25px;
  &.dark {
    .icon-with-text {
      color: $gray100;
    }
  }
  @include sm {
    flex-direction: column;
    gap: 4px;
    display: flex;
    width: 100%;
  }
  .icon-with-text {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: $gray40;
    @include sm {
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
    &:not(&:first-child)::before {
      position: absolute;
      @include content;
      width: 1px;
      height: 15px;
      left: -13px;
      top: 50%;
      transform: translateY(-50%);
      background-color: $gray80;
      @include sm {
        display: none;
      }
    }
  }
}
.voting-wrap {
  .hyphen {
    display: inline-flex;
    align-items: center;
    height: 48px;

    @include sm {
      height: 37px;
    }
  }

  .header {
    // [24.04.01] Rewards 메뉴 추가건 (공통 스타일 변경에 따른 수정 반영)
    // border-bottom: 0;
    .header-gnb {
      li.ant-menu-item {
        overflow: unset;
        margin: 0;
      }
    }
  }
  .mobile-gnb {
    position: fixed;
    z-index: 100;
    width: 0;
    height: 100vh;
    height: calc($vh * 100);
    right: 0;
    top: 0;
    overflow: hidden;
    background-color: $gray100;
    transition: width 0.3s;

    &.show {
      width: 100%;
    }
    .gnb-inner {
      height: 100%;
      padding: 12px 20px 24px;
      text-align: right;
      .header-gnb {
        position: static;
        transform: none;
        flex-direction: column;
        text-align: left;
        gap: 0;
        .ant-menu-item {
          margin-bottom: 0;
          margin-top: 0;
          padding: 0;
          height: auto;
          line-height: 20px;
          border-bottom: 1px solid $gray90;
          &.active {
            a {
              text-decoration: none;
              color: $deepBlue;
            }
          }
          a {
            padding: 12px 0 16px;
            font-weight: 700 !important;
            font-size: 16px;
            color: $gray20;
          }
          & + .ant-menu-item {
            a {
              padding-top: 16px;
            }
          }
        }
      }
    }
    .btn-close {
      position: relative;
      right: 0;
    }
  }
  main {
    // padding-top: 48px;
    // padding-bottom: 70px;
    background-color: $bgColor;
    &.content-wrap {
      padding-top: 38px;
    }
    .inner {
      @include maxWidth;
      margin-top: 48px;
      padding-bottom: 70px;

      @include sm {
        padding-bottom: 50px;
      }
    }
  }
  .voting-top-wrap {
    background: $gray10; // [24.04.01] Rewards 메뉴 추가건 (공통 스타일 변경에 따른 수정 반영)
    padding: 64px 0;
    .inner {
      @include maxWidth;
      color: $gray90;
      .desc {
        margin-top: 12px;
        color: rgba($gray90, 0.9);
        font-size: 14px;
        line-height: 26px;
        word-wrap: break-word;
      }
      .btn-prev {
        @include sm {
          margin-top: -10px;
        }
        & + .voting-title {
          margin-top: 20px;
        }
      }

      @include sm {
        position: relative;
      }
    }
    @include sm {
      padding: 40px 0;
    }
  }

  .top-status-wrap {
    margin-top: 12px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    @include sm {
      flex-direction: column;
      align-items: flex-start;
    }
    .btn-wrap {
      margin-top: 0;
      display: flex;
      gap: 12px;
      @include sm {
        gap: 0;
      }
    }
    & + .voting-title {
      margin-top: 16px;
    }
    .voting-status {
      @include sm {
        // order: inherit !important;
        border-radius: 28px !important;
        width: 104px !important;
        justify-content: center !important;
        padding-left: 0 !important;
        // margin-top: 20px;
      }
    }
  }
  .voting-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include sm {
      flex-direction: column;
      .button-wrap {
        margin-top: 26px;
      }
    }
    .title {
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      color: $gray100;
      word-break: break-all;
    }
  }
  .voting-status {
    &.active {
      @include statusBg($deepBlue);
    }
    &.inprogress {
      @include statusBg($deepBlue);
    }
    &.approved {
      @include statusBg($positive);
    }
    &.rejected {
      @include statusBg($critical);
    }
    &.ready {
      @include statusBg($gray60);
    }
  }
  .dashboard-wrap {
    display: flex;
    gap: 48px;
    margin-top: 56px;
    @include md {
      gap: 24px;
    }
    @include sm {
      display: none;
    }
  }
  .dashboard-module {
    display: flex;
    width: calc(100% - 264px - 48px);
    justify-content: space-between;
    background-color: rgba(#222639, 0.6);
    .dashboard-block {
      background-color: transparent;
    }
    @include md {
      width: calc(100% - 264px - 24px);
    }
  }
  .dashboard-block {
    display: inline-flex;
    padding: 32px 32px 42px;
    width: 264px;
    flex-direction: column;
    gap: 2px;
    background-color: rgba(#222639, 0.6);

    @at-root .dashboard-module .dashboard-block {
      width: 100%;
      background-color: transparent;

      & + .dashboard-block {
        position: relative;
        &::before {
          @include content;
          position: absolute;
          left: 0;
          top: 50%;
          width: 1px;
          height: 60px;
          background-color: $gray100;
          opacity: 0.1;
          transform: translateY(-50%);
        }
      }
    }
    strong {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
    }
    span {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      color: $gray60;
      gap: 6px;
      &:not(.default) {
        &::before {
          @include content;
          width: 9px;
          height: 9px;
          border-radius: 100%;
        }
        &.active {
          &::before {
            background-color: $deepBlue;
          }
        }
        &.ready {
          &::before {
            background-color: $gray60;
          }
        }
        &.approved {
          &::before {
            background-color: $positive;
          }
        }
        &.rejected {
          &::before {
            background-color: $critical;
          }
        }
      }
    }
  }
  .voting-title-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    strong {
      color: $gray20;
      font-weight: 700;
    }
    span {
      font-weight: 700;
    }
    &.sm {
      font-size: 16px;
      gap: 6px;
      span {
        color: $deepBlue;
      }
    }
    &.md {
      font-size: 20px;
      gap: 8px;
      span {
        color: $gray70;
      }
    }

    .detail-search-area {
      margin-left: auto;
      max-height: 48px;
      display: inline-flex;
      margin-top: -10px;

      @include sm {
        .voting-filter.search-type {
          width: auto;
          margin-left: auto;
        }
      }

      &.active {
        @include sm {
          width: 100%;
          justify-content: flex-end;
          .voting-search {
            margin-left: 0;

            .search-text-area {
              width: 100%;
            }
          }
        }
      }
    }
    + .voting-title-exp {
      font-size: 14px;
      line-height: 20px;
      margin-top: 10px;
      margin-bottom: -7px;

      strong {
        font-weight: 600;
      }
    }
  }

  .voting-list-section {
    margin-top: 32px;
    & + .voting-list-section {
      margin-top: 50px;
    }

    .section-inner {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px 0;
    }

    &.revoke-item {
      .voting-title-wrap {
        > strong {
          color: $critical;
        }
      }

      .section-inner {
        .voting-list-block-wrap {
          border: 2px solid $critical;
          position: relative;

          &:hover {
            border: 2px solid $critical;
          }
          &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: $critical;
            opacity: 0.2;
          }
        }
      }
    }
  }
  .section-inner {
    margin-top: 20px;
  }
  .content-filter-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid $gray10;
  }
}

.voting-list-block-wrap {
  position: relative;
  background-color: $gray100;
  border-radius: 4px;
  padding: 16px 28px;
  width: 100%;
  border: 2px solid transparent;
  transition: border 0.3s;

  @include sm {
    padding: 16px;
  }
  &:hover {
    border: 2px solid rgba($deepBlue, 0.5);
  }
  .wallet-info {
    display: flex;
    width: 100%;
    gap: 17px;
    span {
      display: inline-block;
      position: relative;
      z-index: 0;
      font-size: 12px;
      color: $gray60;
      line-height: 20px;
      font-feature-settings: "calt" off;
      & + span {
        font-feature-settings: "ss20" on, "lnum" on, "tnum" on;
        &::before {
          @include content;
          position: absolute;
          width: 1px;
          background-color: #bfbfbf;
          height: 9px;
          left: -9px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    @include sm {
      position: absolute;
      transform: translateY(40px);
    }
  }
  .list-title-wrap {
    display: flex;
    margin-top: 2px;
    width: 100%;
    justify-content: space-between;
    @include sm {
      flex-direction: column;
      gap: 5px;
    }
    .title {
      font-size: 20px;
      line-height: 28px;
      color: $gray10;
      font-weight: 700;
      word-break: break-all;
      @include sm {
        display: flex;
        order: 2;
        margin-top: 26px;
      }
    }
  }
  .list-desc-wrap {
    font-size: 14px;
    line-height: 26px;
    color: #595959;
    width: 70%;
    min-width: -webkit-fill-available;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    word-break: keep-all;
    @include sm {
      width: 100%;
      margin-top: 5px;
      -webkit-line-clamp: 2;
      line-height: 22px;
    }
  }
  .list-bottom-info {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    gap: 40px;
    @include sm {
      flex-direction: column;
      gap: 16px;
    }
    .tx-state {
      position: absolute;
      font-size: 14px;
      right: 0;
      bottom: 5px;

      &.tx-before {
        color: $gray50;
      }
      &.tx-complete {
        color: $gray10;
        font-weight: 700;
      }

      @include sm {
        position: static;
        width: 100%;
        text-align: left;
      }
    }
  }
  & + .voting-list-block-wrap {
    margin-top: 20px;
  }

  &.revoke {
    .wallet-info {
      @include sm {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
      }
    }
    .list-title-wrap {
      .voting-status {
        display: none;
      }
    }
    + .button-wrap {
      border: 1px solid $critical;
      color: $critical;
      position: absolute;
      right: 30px;
      bottom: 18px;
      z-index: 1;

      @include sm {
        right: 18px;
        bottom: 52px;
      }
    }
  }

  @at-root .dark & {
    padding: 24px 32px;
    background-color: $gray30;
    border-radius: 0;
    border: 1px solid $gray40;
    &:hover {
      border: 1px solid rgba($gray80, 0.5);
    }
  }
}
// voting-filter 컴포넌트
.voting-filter {
  min-width: 117px;

  @include sm {
    width: 100%;
  }

  &.ant-select {
    width: auto;

    @include sm {
      width: 100%;
    }
  }

  .ant-select-arrow {
    right: 16px;
    top: 50%;
    margin-top: 0;
    transform: translateY(-50%);
    transition: transform 300ms;

    svg {
      width: 15px;
      height: 15px;
    }
  }
  .ant-select-selection__rendered {
    margin-left: 20px;
  }
  &.search-type {
    .ant-select-selection {
      background-color: $bgColor;
    }
    &.ant-select-focused {
      .ant-select-selection {
        background-color: $gray100;
      }
    }
  }
  .ant-select-selection {
    min-height: 37px;
    display: flex;
    align-items: center;
  }
  // search-type style
  &.search-type {
    min-width: 182px;

    .ant-select-selection {
      min-height: 48px;
      border-radius: 0;
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(180deg) translateY(50%);
    }
  }

  @include md {
  }

  @include sm {
  }
}
// voting-filter 드랍다운 스타일
.ant-select-dropdown {
  .ant-select-dropdown-menu {
    padding: 4px;
  }

  .ant-select-dropdown-menu-item-selected {
    font-weight: 400;
  }
  .ant-select-dropdown-menu-item-active:not(
      .ant-select-dropdown-menu-item-disabled
    ) {
    background: rgba(50, 60, 255, 0.04);
  }
  .ant-select-dropdown-menu-item:hover:not(
      .ant-select-dropdown-menu-item-disabled
    ) {
    background: rgba(50, 60, 255, 0.04);
  }
  .ant-select-dropdown-menu-item {
    display: flex;
    align-items: center;
  }
  &.voting {
    .ant-select-dropdown-menu-item {
      min-height: 37px;
    }
  }

  &.search-type {
    .ant-select-dropdown-menu-item {
      min-height: 48px;
    }
  }

  &.proposal {
    .ant-select-dropdown-menu {
      padding: 0;
      background: $gray30;
      .ant-select-dropdown-menu-item {
        min-height: 80px;
        color: $gray100;
        font-size: 20px;
        padding-left: 31px;
      }
      .ant-select-dropdown-menu-item {
        background: rgba(34, 38, 57, 0.6);
        backdrop-filter: blur(10px);
      }
    }
  }
}

.voting-search {
  display: inline-flex;
  .voting-search-btn {
    .a11y {
      position: absolute !important;
      display: block !important;
      overflow: hidden !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      border: 0 !important;
      margin: 0 !important;
      clip: rect(1px, 1px, 1px, 1px) !important;
      clip-path: inset(50%) !important;
      word-break: initial !important;
      word-wrap: initial !important;
    }
  }
  .search-text-area {
    position: absolute;
    width: 0px;
    right: 0;
    overflow: hidden;
    transition: width 0.3s ease;

    &.active {
      width: 257px;
    }
    .search-input {
      height: 100%;
      width: 100%;
      border: 1px solid #d9d9d9;
      background-color: $gray100;
      color: $gray20;
      min-height: 48px;
      overflow: hidden;
      padding: 0 44px;
      background: $gray100 url("#{$rootPath}/ico_search.svg") no-repeat left
        16px center;

      & + .search-close-btn {
        display: block;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        path {
          fill: $gray60;
        }
      }

      @include sm {
        width: 100%;
      }
    }
  }
}

.detail-search-area {
  position: relative;
  .voting-search {
    margin-left: 24px;
    width: min-content;

    @include sm {
      margin-left: 8px;
    }

    &.active {
      width: auto;
      display: flex;
    }
  }
}

.voting-proposal-select {
  margin-top: 48px;
  display: flex;
  min-height: 80px;
  @include sm {
    margin-top: 26px;
    flex-direction: column;
  }

  .select-label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // padding: 0 32px;
    font-size: 20px;
    background: rgba(34, 38, 57, 0.6);
    backdrop-filter: blur(10px);
    color: rgba($gray100, 0.5);
    min-width: 206px;
    @include sm {
      min-width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 16px 16px 5px;
      font-size: 18px;
    }
    & + .voting-filter.proposal {
      margin-left: 2px;
      @include sm {
        margin-left: 0;
      }
    }
  }

  .voting-filter.proposal {
    flex: 100%;

    .ant-select-selection {
      height: 100%;
      border-radius: 0;
      font-size: 20px;
      width: 100%;
      background: rgba(34, 38, 57, 0.6);
      backdrop-filter: blur(10px);
      color: $gray100;
      box-shadow: none;
      border: none;
      .ant-select-selection__rendered {
        margin-left: 31px;
        width: calc(100% - 90px);
        @include sm {
          margin: 0;
          padding: 16px;
          width: calc(100% - 30px);
          font-size: 18px;
        }
      }
    }
    .ant-select-arrow {
      right: 24px;
      @include sm {
        right: 10px;
      }

      svg {
        width: 30px;
        height: 30px;
        @include sm {
          width: 24px;
          height: 24px;
        }
        path {
          stroke: $gray100;
        }
      }
    }
  }
}

// proposal input
.textfield-contain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .textfield-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 40px;
    }

    @include sm {
      gap: 20px;
    }

    .voting-input-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;

      // block distribution
      .box-wrap {
        width: 65%;

        @include sm {
          width: 100%;
        }

        .multi-input-box {
          width: 100%;
        }

        .error-massage {
          @include errMsgState;
        }
      }

      .button-input-box {
        display: flex;
        width: 65%;

        @include sm {
          width: 100%;
        }
        .input-wrap {
          width: 100%;
        }
        .input-button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: $gray20;
          color: $gray100;
          padding: 0 26px;
          white-space: nowrap;
          &:disabled {
            opacity: 0.3;
          }
        }
      }
      .multi-input-box {
        display: flex;
        flex-wrap: wrap;
        width: 65%;
        gap: 0 11px;

        @include sm {
          width: 100%;
          gap: 5px;
        }

        .hyphen {
          padding-top: 24px;
          display: flex;
          align-items: center;
          height: calc(48px + 24px);
          color: #595959;
        }

        @include sm {
          .input-cell {
            width: calc(50% - 20px);
          }
        }
        .input-cell-title {
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 4px;
        }
        .input-wrap {
          width: calc(
            ((741px - 32.5px * var(--hyphen-count)) / var(--input-count))
          );

          @include sm {
            width: 100%;
          }

          .input-area {
            width: 100%;
          }
        }

        .error-massage {
          @include errMsgState;
        }
      }
      .description {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-top: 4px;
        color: #8c8c8c;
        padding-left: 35%;
        @include sm {
          padding: 0;
        }
      }

      @include sm {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }

      strong {
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
      }

      .important {
        color: red;
      }

      .required {
        color: red;
      }

      .double-input-box {
        display: flex;
        flex-direction: column;
        width: 65%;
        gap: 10px;

        &.proposal-title-box {
          input {
            text-align: start;
          }
        }

        .box-wrap {
          display: flex;
          flex-direction: column;
          width: 100%;

          .description {
            padding-left: 10.5%;

            @include sm {
              padding-left: 0;
            }
          }

          .input-wrap {
            width: 100%;
          }
        }

        .error-massage {
          width: 91%;
          margin-left: 9%;
          @include errMsgState;

          @include sm {
            margin-left: 0;
          }
        }

        @include sm {
          width: 100%;

          .input-wrap {
            .description {
              display: none;
            }
          }
        }

        + .description {
          padding-left: 42%;

          @include sm {
            padding-left: 0;
          }
        }
      }
    }
  }

  .voting-duration {
    display: flex;
    border-top: 1px solid #d9d9d9;
    width: 100%;
    margin-top: 40px;
    padding-top: 24px;
    justify-content: space-between;

    @include sm {
      flex-direction: column;
      gap: 30px;
    }

    .voting-duration-wrap {
      gap: 24px;
      display: flex;
      align-items: center;

      @include sm {
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
      }

      strong {
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
      }

      .important {
        color: red;
      }

      .required {
        color: red;
      }
    }

    .submit-btn {
      color: #fff;
      background-color: #323cff;
      opacity: 0.3;
      border-radius: 0;
      border: none;
      width: 160px;
      height: 52px;

      @include sm {
        width: 100%;
      }
    }
  }

  // my info footer
  .myinfo-footer {
    display: flex;
    border-top: 1px solid #d9d9d9;
    width: 100%;
    margin-top: 40px;
    padding-top: 24px;
    justify-content: flex-end;
  }
}

.voting-proposal {
  padding: 30px;
  background-color: #fff;
  @include sm {
    padding: 16px;
  }
}

.input-wrap {
  display: flex;
  width: 65%;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  @include sm {
    width: 100%;
  }

  &.disabled {
    .input-area {
      background-color: #f2f2f2;

      input {
        font-feature-settings: "calt" off;
        &::input-placeholder {
          color: #a6a6a6;
        }

        &:placeholder {
          color: #a6a6a6;
        }

        &::placeholder {
          color: #a6a6a6;
        }

        &:input-placeholder {
          color: #a6a6a6;
        }
      }
    }
    .textarea-wrap {
      background-color: #f2f2f2;
      border: none !important;

      span {
        color: #a6a6a6 !important;
      }
      textarea {
        font-feature-settings: "calt" off;
        border: none !important;
      }

      textarea {
        &::input-placeholder {
          color: #a6a6a6;
        }

        &:placeholder {
          color: #a6a6a6;
        }

        &::placeholder {
          color: #a6a6a6;
        }

        &:input-placeholder {
          color: #a6a6a6;
        }
      }
    }
  }

  &.multiline {
    align-items: flex-start;

    @include sm {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #8c8c8c;
      margin-top: 4px;
      display: block;
    }
    .error-massage {
      @include errMsgState;
    }
    .textarea-wrap {
      width: 100%;
      position: relative;
      border: 1px solid #d9d9d9;
      padding: 14px 16px 25px 16px;
      &.focus-on {
        border: 1px solid #323cff;
      }

      textarea {
        font-feature-settings: "calt" off;
        background-color: transparent;
        border: none;
        width: 100%;
        height: 104px;
        resize: none;
        text-align: start;

        @include sm {
          width: 100%;
          font-size: 14px;
        }

        &:disabled {
          opacity: 1;
          color: #d9d9d9;
        }
        &:focus {
          outline: none;
          border: none;
        }
      }

      span {
        position: absolute;
        bottom: 5%;
        right: 2%;
        transform: translate(-2%, -5%);
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #8c8c8c;
      }
    }

    &.error {
      .textarea-wrap {
        border: 1px solid $critical;
      }
    }
  }

  .input-top-area {
    width: 100%;

    &.prefix {
      display: flex;
      flex-direction: row;
      align-items: center;

      @include sm {
        flex-direction: column;
        align-items: flex-start;

        > span {
          margin-bottom: 10px;
        }
      }

      > span {
        width: 12%;
        color: $gray20;
        font-size: 15px;
      }
    }

    @include sm {
      width: 100%;
    }
    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #8c8c8c;
      margin-top: 4px;
      display: block;
    }
    .error-massage {
      @include errMsgState;
    }

    .input-area {
      width: 100%;
      position: relative;
      border: 1px solid #d9d9d9;
      display: flex;

      &.focus-on {
        border: 1px solid #323cff;
      }
      //에러 케이스
      &.error {
        border: 1px solid $critical;
        background: none;

        &.focus-on {
          border: none;
        }

        input {
          outline: none;
          border: none;

          &:focus {
            outline: none;
          }
        }

        &.focus-on {
          border: 1px solid #eb524f;
          &::after {
            display: none;
          }
        }
      }
      //suffix일때 케이스
      &.suffix {
        &.not-value {
          input {
            padding: 10px 16px 10px 16px;
          }
        }
        input {
          text-align: end;
          font-weight: 400;
          padding: 10px 0px 10px 35px;

          @include sm {
            padding: 10px 0px 10px 35px;
          }

          &.ant-calendar-picker-input {
            text-align: start;
          }
        }

        .text-place {
          display: flex;
          align-items: center;
          white-space: nowrap;
          padding: 11px 16px 10px 4px;
          color: #737373;

          @include sm {
            font-size: 14px;
          }
        }
      }

      //perfix일때 케이스
      &.prefix {
        &.not-value {
          input {
            padding: 10px 16px 10px 16px;
          }
        }
        input {
          order: 2;
          text-align: start;
          font-weight: 400;
          padding: 10px 35px 10px 0px;

          @include sm {
            padding: 10px 35px 10px 0px;
          }
        }

        .text-place {
          display: flex;
          align-items: center;
          white-space: nowrap;
          padding: 11px 8px 10px 15px;

          @include sm {
            font-size: 14px;
          }
        }
      }

      &.readonly {
        background-color: #f2f2f2;
      }

      //input 기본값
      input {
        border: none;
        background-color: transparent;
        height: 48px;
        width: 100%;
        padding: 8px 16px 8px 16px;
        text-align: start;

        @include sm {
          font-size: 14px;
          height: 44px;
        }

        &:disabled {
          opacity: 1;
          color: $gray20;
        }

        &:read-only {
          opacity: 1;
          color: $gray20;
        }

        &:focus {
          outline: none;
        }
      }
      // antd range picker css 수정
      .ant-calendar-picker-input {
        display: flex;
        height: fit-content;
        padding: 0;
        border: 0;

        span {
          &.ant-calendar-range-picker-separator {
            align-self: center;
          }
        }
      }
    }
  }
}

// staking modal
.staking-modal {
  max-width: 486px;
  position: relative;

  &.scroll {
    .ant-modal-body {
      max-height: 132px;
      overflow-y: auto;
    }
  }

  &.voting-leave {
    opacity: 0;

    @include sm {
      opacity: 1;
      -webkit-animation: drawerClose 0.3s forwards;
      animation: drawerClose 0.3s forwards;
    }
  }

  .ant-modal-content {
    border-radius: 0;
    @include sm {
      border-radius: 12px 12px 0 0;
    }

    .ant-modal-header {
      border-bottom: none;
      padding: 24px 28px;

      @include sm {
        padding: 16px 20px 20px 20px;
      }

      .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
      }

      .sub-title {
        font-size: 14px;
        font-weight: 400;
        color: $gray40;
        line-height: 20px;
      }
    }

    .ant-modal-body {
      padding: 0 28px;
      margin-bottom: 24px;

      // error modal
      .error-icon {
        padding: 50px 0 74px;
        gap: 5px;
        align-items: center;
        flex-direction: column;
      }

      > div {
        word-break: break-all;
      }

      @include sm {
        padding: 0 20px;
      }
    }

    .ant-modal-footer {
      display: flex;
      padding: 12px 28px 24px 28px;

      @include sm {
        padding: 0 20px 16px 20px;
      }
      button {
        min-height: 52px;
        flex: 1;
        font-size: 16px;
        border-radius: 0;

        @include sm {
          & + button {
            margin-left: 0;
          }
        }
      }

      .voting-ok-btn {
        background-color: $gray20; // [24.04.01] Rewards 메뉴 추가건 (공통 스타일 변경에 따른 수정 반영)
        color: $gray100;
        border: none;
      }
      .disabled {
        background-color: $gray80;
      }

      // error go to explorer btn
      a {
        &.ant-btn {
          min-height: 52px;
          display: flex;
          align-items: center;
          justify-items: center;
        }
      }
    }

    .ant-modal-close {
      top: 28px;
      right: 28px;

      @include sm {
        top: 20px;
        right: 20px;
      }
      .ant-modal-close-x {
        width: 24px;
        height: 24px;
        line-height: inherit;
      }
    }
  }

  @include sm {
    position: fixed;
    top: auto;
    display: block;
    bottom: -100%;
    opacity: 1;
    width: 100% !important;
    -webkit-animation: drawer 0.3s forwards;
    animation: drawer 0.3s forwards;
    overflow: hidden;
    max-width: inherit;
    padding: 0;
    margin: 0;

    .ant-modal-footer {
      border-top: none;
    }

    .voting-cancel-btn {
      display: none;
    }
  }

  @-webkit-keyframes drawer {
    0% {
      bottom: -100%;
    }

    100% {
      bottom: 0;
    }
  }

  @keyframes drawer {
    0% {
      bottom: -100%;
    }

    100% {
      bottom: 0;
    }
  }
}

// TxHashAddModal
.tx-hash-add-modal {
  width: 700px !important;
  position: relative;

  &.scroll {
    .ant-modal-body {
      position: relative;
      min-height: 360px;
      max-height: 360px;
      overflow-y: scroll;

      @include sm {
        min-height: 160px;
        max-height: calc(($vh * 100) - 136px - 80px);
      }
    }
  }

  &.tx-hash-add-leave {
    opacity: 0;

    @include sm {
      opacity: 1;
      -webkit-animation: drawerClose 0.3s forwards;
      animation: drawerClose 0.3s forwards;
    }
  }

  .ant-modal-content {
    border-radius: 0;
    @include sm {
      border-radius: 12px 12px 0 0;
    }

    .ant-modal-header {
      border-bottom: none;
      padding: 24px 28px;

      @include sm {
        padding: 16px 20px 20px 20px;
      }

      .ant-modal-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
      }

      .sub-title {
        font-size: 14px;
        font-weight: 400;
        color: $gray40;
        line-height: 20px;
      }
    }

    .ant-modal-body {
      padding: 0 28px;
      margin-bottom: 24px;

      @include sm {
        padding: 0 20px;
      }
    }

    .ant-modal-footer {
      display: flex;
      padding: 12px 28px 24px 28px;

      @include sm {
        padding: 0 20px 16px 20px;
      }
      button {
        min-height: 52px;
        flex: 1;
        font-size: 16px;
        border-radius: 0;
        &:disabled {
          cursor: default;
          opacity: 0.5;
        }

        @include sm {
          & + button {
            margin-left: 0;
          }
        }
      }

      .tx-hash-add-apply-btn {
        background-color: $gray20; // [24.04.01] Rewards 메뉴 추가건 (공통 스타일 변경에 따른 수정 반영)
        color: $gray100;
        border: none;
      }

      // error go to explorer btn
      a {
        &.ant-btn {
          min-height: 52px;
          display: flex;
          align-items: center;
          justify-items: center;
        }
      }
    }

    .ant-modal-close {
      top: 28px;
      right: 28px;

      @include sm {
        top: 20px;
        right: 20px;
      }
      .ant-modal-close-x {
        width: 24px;
        height: 24px;
        line-height: inherit;
      }
    }
  }

  @include sm {
    position: fixed;
    top: auto;
    display: block;
    bottom: -100%;
    opacity: 1;
    width: 100% !important;
    -webkit-animation: drawer 0.3s forwards;
    animation: drawer 0.3s forwards;
    overflow: hidden;
    max-width: inherit;
    padding: 0;
    margin: 0;

    .ant-modal-footer {
      border-top: none;
    }

    .tx-hash-add-cancel-btn {
      display: none;
    }
  }

  @-webkit-keyframes drawer {
    0% {
      bottom: -100%;
    }

    100% {
      bottom: 0;
    }
  }

  @keyframes drawer {
    0% {
      bottom: -100%;
    }

    100% {
      bottom: 0;
    }
  }
}

@keyframes drawerClose {
  0% {
    bottom: 0%;
  }

  100% {
    bottom: -100%;
  }
}

.staking-wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;

  [class*="voting-filter"] {
    min-width: none;
    width: 100%;

    [class*="ant-select-selection"] {
      border-radius: 0;
      height: 48px;

      [class*="ant-select-selection-selected-value"] {
        display: flex !important;
        align-items: center !important;
      }
    }
  }

  [class*="input-wrap"] {
    width: 100%;

    [class*="input-area"] {
      &.suffix {
        @include sm {
          .text-place {
            height: 37px;
          }
        }
        input {
          padding: 10px 0 10px 16px;
        }
      }

      &.prefix {
        @include sm {
          .text-place {
            height: 37px;
          }
        }
        input {
          padding: 10px 16px 10px 0px;
        }
      }
    }
  }
}

.sub-info {
  width: 100%;
  border: none;
  padding: 8px 16px;
  margin-top: 8px;
  background-color: #f1f2f9;

  .sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #1a1a1a;
  }
  .sub-info-detail {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #8c8c8c;
  }
}

.empty-area {
  padding: 36px 0;
  .empty-notice {
    display: flex;
    flex-direction: column;
    align-items: center;
    .empty-title {
      font-size: 16px;
      color: $gray60;
      line-height: 28px;
    }
  }
}
// [24.04.01] Rewards 메뉴 추가건 (공통 스타일 변경에 따른 수정 반영)
.unknown-wrap,
.modal-info-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 50px 0;

  .error-detail,
  .check-detail {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    &::before {
      display: inline-block;
      content: "";
      width: 36px;
      height: 36px;
      background: url("#{$rootPath}/ico_unknown.svg") no-repeat center center;
    }
  }

  .check-detail {
    &::before {
      background: url("#{$rootPath}/ico_check.svg") no-repeat center center;
    }
  }
}
// [24.04.01] Rewards 메뉴 추가건 (공통 스타일 변경에 따른 수정 반영)
.modal-info-wrap {
  padding: 0;
  min-height: 160px;
  gap: 20px;
  &.check {
    padding-top: 40px;
  }
}
// [24.04.01] Rewards 메뉴 추가건 (공통 스타일 변경에 따른 수정 반영)
.gray-info-list-wrap {
  width: 100%;
  padding: 16px;
  background-color: $bgColor;
}
// [24.04.01] Rewards 메뉴 추가건 (공통 스타일 변경에 따른 수정 반영)
.claim-address-wrap {
  .title {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    color: $gray10;
  }
  .input-wrap {
    width: 100%;
    input {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      color: $gray10;
      white-space: nowrap;
      word-break: normal;
    }
  }

  // [24.04.11] 기획 변경에 따른 수정 반영
  .ant-radio-group {
    .ant-radio-wrapper {
      color: $gray10;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
    .ant-radio {
      .ant-radio-inner {
        border-color: $gray80;
      }
      &.ant-radio-checked {
        &:after {
              border: 1px solid $gray10;
        }
        .ant-radio-inner {
          border-color: $gray10;
          &:after {
            background-color: $gray10;
          }
        }
      }
      
    }
    + .title {
      margin-top: 16px;
    }
  }
}

.info-list {
  display: block;
  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include sm {
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
    .title {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $gray20;
    }
    .result {
      &.underline {
        text-decoration: underline;
      }
      display: flex;
      align-items: center;
      gap: 4px;
      color: $gray20;
      word-break: break-all;

      strong {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        color: $gray10;

        @include sm {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
        }
      }
      .svg-icon {
        display: inline-block;
        width: 24px;
        height: 24px;

        @include sm {
          width: 20px;
          height: 20px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &.row {
      > span {
        display: block;
      }
      .result {
        margin-top: 8px;
      }
    }
    & + li {
      margin-top: 17px;
      &:before {
        content: "";
        position: absolute;
        top: -9px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $gray80;
      }
      @include sm {
        margin-top: 25px;
        &:before {
          top: -13px;
        }
      }
    }
  }
}

.day-select-wrap {
  display: flex;
  flex-direction: column;

  .label-list {
    display: flex;
    flex-direction: column;
    .label-option {
      display: flex;
      position: relative;
    }

    input[type="radio"] {
      visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      &:checked {
        & + label {
          background-color: rgba(50, 60, 255, 0.06);
          &::after {
            content: "";
            position: absolute;
            visibility: visible;
            width: 20px;
            height: 20px;
            top: 50%;
            right: 5px;
            transform: translate(-5px, -50%);
            display: inline-block;
            background: url("#{$rootPath}/ico_voting_check.svg") no-repeat;
          }
        }
      }
    }

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 44px;
      background-color: transparent;
      padding: 12px;
    }
    text-align: center;
  }
}

.detail-vote-cont {
  display: flex;
  gap: 48px;
  min-height: 257px;
  @include sm {
    flex-direction: column;
  }

  & + .detail-date-cont,
  & + .detail-vote-cont {
    margin-top: 48px;
  }
}

.status-content {
  flex: 1;
  background: $gray100;
  border: 1px solid $gray90;
  box-shadow: 0px 10px 10px rgba(46, 46, 46, 0.04);
  @include sm {
    padding-bottom: 32px;
  }

  .voting-title-wrap {
    display: block;
    padding: 19px 32px;
    font-weight: 700;
    border-bottom: 1px solid $gray90;
    @include sm {
      padding: 16px;
    }
    strong {
      line-height: 28px;
    }
  }
}

.chart-area {
  padding: 14px 32px 0 32px;
  @include sm {
    padding: 28px 16px 0;
  }
}
.legend-area {
  display: flex;
  justify-content: space-between;

  .legend-title,
  .legend-unit {
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
  }
  .legend-unit {
    @at-root .dark & {
      &.yes-type {
        color: $positive;
      }
      &.no-type {
        color: $red;
      }
    }
  }

  .sub-unit {
    color: #737373;

    @at-root .dark & {
      color: $gray70;
    }
  }

  & + .voting-stick-chart {
    margin-top: 12px;
    @at-root .dark & {
      @include md {
        margin-top: 10px;
      }
      @include sm {
        margin-top: 10px;
      }
    }
  }
}

.stick-chart {
  & + .stick-chart {
    margin-top: 32px;

    @include md {
      @at-root .dark & {
        margin-top: 16px;
      }
    }
    @include sm {
      @at-root .dark & {
        margin-top: 16px;
      }
    }
  }
}
.cast-content {
  flex: 1;
  background: #08097d url("#{$rootPath}/ico_vote_bg.svg") no-repeat left bottom;

  @include sm {
    min-height: 257px;
  }
  .voting-title-wrap {
    display: block;
    padding: 19px 32px;
    font-weight: 700;
    border-bottom: 1px solid rgba($gray90, 0.16);
    @include sm {
      padding: 16px;
    }
    strong {
      line-height: 28px;
      color: $gray100;
    }
  }

  .vote-btn-area {
    padding: 24px 32px 0 32px;
    @include sm {
      padding: 28px 16px 0;
    }
    .btn-wrap {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      margin-top: 0;
      .voting-check-wrap {
        width: 100%;
        position: relative;
        label {
          flex: 1;
          border: 1px solid $gray100;
          border-radius: 100px;
          min-height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $gray100;
        }
      }
    }
    input[type="radio"] {
      visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      &:checked {
        & + label {
          background-color: #fff;
          color: #5e5ff5;
        }
      }
      &:disabled {
        & + label {
          opacity: 0.5;
        }
      }
    }

    .text-banner {
      width: 100%;
      margin-top: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 52px;
      background-color: $gray100;
      color: $deepBlue;

      &:disabled {
        background-color: #b7b8d4;
        cursor: no-drop;
        color: rgba($deepBlue, 0.7);
      }
    }
  }
}

.detail-date-cont {
  background: $gray100;
  padding: 24px 32px;
  min-height: 132px;
  box-shadow: 0px 10px 10px rgba(46, 46, 46, 0.04);
  .unit-date {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
      line-height: 20px;
      color: $gray50;
      @include sm {
        font-weight: 700;
      }
    }
  }
  .date-value {
    display: flex;
    justify-content: space-between;

    @include sm {
      margin-top: 8px;
      gap: 12px;
    }
    span {
      font-size: 14px;
      line-height: 26px;
      color: $gray20;
      @include sm {
        line-height: 20px;

        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .stick-chart {
    margin-top: 10px;
  }
  .description-value {
    // display: flex;
    justify-content: space-between;
    margin-top: 2px;
    white-space: pre-wrap;

    @include sm {
      margin-top: 8px;
      gap: 12px;
    }
    div {
      font-size: 14px;
      line-height: 26px;
      word-break: break-all;
      color: $gray20;
      @include sm {
        line-height: 20px;

        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .vote-list {
    display: flex;
    padding-bottom: 12px;
    justify-content: space-between;
    .vote-count {
      color: $deepBlue;
    }
    span {
      font-size: 14px;
      line-height: 20px;
      color: $gray50;
      font-weight: 700;
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0px solid #f0f0f0;
    padding: 14px;
    .vote-decision {
      font-weight: 700;
    }
  }
  .ant-table-placeholder {
    border: none;
  }

  & + .detail-date-cont {
    margin-top: 48px;
  }
}

.voting-stick-chart {
  width: 100%;
  height: 4px;
  background-color: $gray80;
  position: relative;

  @at-root .dark & {
    border-radius: 4px;
    overflow: hidden;
  }
  .rate {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .time-tooltip {
    position: absolute;
    font-size: 12px;
    top: 12px;
    line-height: 20px;
    background-color: $gray30;
    text-align: center;
    min-width: 80px;
    color: $gray100;
    padding: 2px 4px 4px 4px;
    transform: translateX(-50%);
    &::before {
      position: absolute;
      content: "";
      top: -4px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      width: 11px;
      height: 11px;
      border-radius: 1px;
      background-color: $gray30;
    }
  }
  &.yes-type {
    @at-root .dark & {
      background-color: rgba($positive, 0.3);
    }
    .rate {
      background-color: $positive;
    }
  }

  &.no-type {
    @at-root .dark & {
      background-color: rgba($red, 0.3);
      .rate {
        background-color: $red;
      }
    }
    .rate {
      background-color: $critical;
    }
  }

  &.date-type {
    .rate {
      background-color: $primary;
    }
  }
}

.detail-top-content {
  margin-top: 32px;
  padding: 20px;
  background: rgba(34, 38, 57, 0.6);
  box-shadow: 0px 20px 15px rgba(69, 59, 183, 0.04);
  backdrop-filter: blur(10px);
  .detail-top-list {
    .detail-list-item {
      display: flex;
      @include sm {
        flex-direction: column;
      }
      dt {
        min-width: 168px;
        color: rgba($gray100, 0.6);
        font-size: 14px;
        line-height: 20px;
      }
      dd {
        @include sm {
          margin-top: 12px;
        }
        .text-bold {
          color: $gray100;
          & + .pocket-address {
            position: relative;
            margin-left: 6px;
            padding-left: 7px;
            font-feature-settings: "calt" off;
            @include sm {
              margin-left: 0;
              padding-left: 0;
              width: 100%;
            }
            &::after {
              position: absolute;
              content: "";
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 14px;
              background: #d9d9d9;
              opacity: 0.4;

              @include sm {
                display: none;
              }
            }
          }
          &:first-child {
            margin-left: 0;
          }
        }
        .pocket-address {
          margin-left: 6px;
          color: rgba($gray100, 0.6);

          @include sm {
            white-space: break-spaces;
            width: calc(100% - 155px);
            display: inline-block;
            word-wrap: break-word;
          }

          em {
            color: $gray100;
          }
        }
        .item-notice {
          white-space: pre-line; // /n 줄바꿈 처리
          font-size: 14px;
          line-height: 20px;
          color: rgba($gray100, 0.3);
          @include sm {
            display: flex;
            flex-direction: column;
            .item-title {
              display: inline-block;
              white-space: nowrap;
              min-width: 150px;
            }
          }
          .text-bold {
            margin-left: 6px;
            word-break: break-all;
            &.no-margin {
              margin-left: 0;
            }
            @include sm {
              margin-left: 0;
              margin-top: 4px;
            }
          }
          & + .item-notice {
            margin-top: 12px;
          }
        }
      }

      & + .detail-list-item {
        margin-top: 12px;
        @include sm {
          margin-top: 24px;
        }
      }
    }
  }
}

.reference-wrap {
  margin-top: 24px;

  .reference-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #737373;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      background: url("#{$rootPath}/ico_desc_warning.svg") no-repeat;
    }
  }

  .reference-list {
    padding: 0 15px;
    margin-top: 7px;
    .reference-list-info {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #888e97;
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 15px;

      &::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #888e97;
        left: 0;
        top: 8px;
        // transform: translate(0, -50%);
      }
    }
  }
}

.voting-wrap {
  .footer-familysite-mo {
    span {
      color: #000000a6;
      opacity: 1;
    }
    .familysite-title {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      outline: none;

      i {
        svg {
          width: 11px;
          path {
            fill: black;
          }
        }
      }
    }
    .familysite-list {
      background-color: #fff;
      color: #000000a6;
      box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
      border-radius: 4px;
      outline: none;

      a:hover {
        color: #5e5ff5;
      }
    }
  }
}

.ant-message {
  bottom: 20px;
  top: initial !important;
  .ant-message-notice-content {
    background-color: $gray30;
    color: #fff;
  }
}

.detail-top-menu {
  display: none;
  z-index: 10;
  @include sm {
    display: block;
  }
  position: absolute;
  right: 20px;
  top: -20px;
  .more-text {
    font-size: 40px;
    letter-spacing: -2px;
  }
  .detail-menu-list {
    background-color: $gray30;
    width: 185px;
    color: $gray100;
    button.button-wrap {
      display: block;
      padding: 11px 16px 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      height: auto;
      width: 100%;
      text-align: left;
    }
  }
}

.hash-add-wrap {
  position: sticky;
  top: 0;
  display: flex;
  gap: 10px;
  background-color: $gray100;
  padding-bottom: 16px;

  .input-wrap {
    width: calc(100% - 110px);
  }

  .button-wrap {
    align-self: flex-start;
    height: 46px;
  }

  button {
    min-width: 100px;
    height: auto;
  }

  .error-massage {
    margin-right: -110px;
  }
}

.hash-add-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0 0 16px;
  .tx-hash {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $bgColor;
    padding: 16px;
    .hash-number {
      font-size: 14px;
      line-height: 20px;
      color: $gray20;
    }
  }

  button {
    display: block;
    line-height: 0;
  }
}
.btn-prev {
  @at-root .dark & {
    svg {
      path {
        fill: $gray10;
      }
    }
  }
}

.proposal-box {
  position: relative;
}

.arrow-left-white {
  @include arrow-left();

  &::before {
    @include arrow-left-before();
  }

  &::after {
    @include arrow-left-after();
  }
}

.arrow-left-black {
  @include arrow-left();

  &::before {
    @include arrow-left-before(#000000);
  }

  &::after {
    @include arrow-left-after(#000000);
  }
}

// about rewards menu
.rewards {
  .voting-title {
    align-items: center;

    @include sm {
      align-items: flex-start;
    }
    .button-wrap.outline {
      padding: 0 32px;

      @include sm {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
}
.voting-rewards-wrap {
  display: flex;
  margin-top: 48px;
  padding: 48px 40px;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(#222639, 0.6);

  @include sm {
    margin-top: 24px;
    padding: 20px;
    justify-content: stretch;
    align-items: flex-start;
    flex-direction: column;
  }

  .rewards-block {
    display: block;

    .title {
      display: block;
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: $gray100;
      @include sm {
        font-size: 14px;
        line-height: 26px;
      }
    }
    .result-wrap {
      display: flex;
      align-items: center;
      gap: 12px;

      strong {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        color: $gray100;

        @include sm {
          font-size: 24px;
          font-weight: 700;
          line-height: 40px;
        }
      }
      .svg-icon {
        display: inline-block;
        width: 40px;
        height: 40px;

        @include sm {
          width: 28px;
          height: 28px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    @include sm {
      & + .button-wrap {
        margin-top: 16px;
        width: 100%;
      }
    }
  }
}

.voting-rewards-list-section {
  .rewards-list {
    margin-top: 20px;
    .ant-table {
      background: $gray100;
      min-height: 132px;
      box-shadow: 0px 10px 10px rgba(46, 46, 46, 0.04);
      .ant-table-thead > tr > th {
        background: $gray100;
      }
      .tx-hash {
        text-decoration: underline;
      }
    }
  }
}
