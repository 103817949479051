@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

.inner {
  width: 1096px;
  margin: 0 auto;

  @include md {
    max-width: 1040px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include sm {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.layout-top {
  background-color: $purple;
  .inner {
    padding-top: 60px;
    padding-bottom: 64px;

    @include md {
      padding-top: 48px;
      padding-bottom: 48px;
    }
    @include sm {
      padding-top: 40px;
      padding-bottom: 0;
    }
  }
}

.detail-container {
  flex: 1;
  .inner {
    padding-top: 60px;
    padding-bottom: 100px;

    @include md {
      padding-top: 48px;
      padding-bottom: 80px;
    }

    @include sm {
      padding-top: 32px;
      padding-bottom: 60px;
    }
  }
}
