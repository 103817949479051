@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

.checkbox-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-top: 8px;

  /* Chrome, Opera */
  &::-webkit-scrollbar {
    position: absolute;
    width: 4px;
    height: 4px;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: $gray60;
    }
  }

  scrollbar-width: thin;

  &.disabled {
    opacity: 0.3;
  }

  .checkbox {
    position: relative;
    cursor: pointer;
    padding: 8px 0;

    &.disabled {
      opacity: 0.3;
    }

    &:hover {
      .item-wrap {
        &::before {
          border-color: $gray60;
          background-color: $gray40;
          @include sm {
            background: none;
            border-color: $gray50;
          }
        }
      }
    }

    .input {
      &:checked {
        & + .item-wrap {
          &::before {
            border-color: $purple2;
            background: url("#{$publicPath}/assets/images/ico_checked.svg") 50%
              50% no-repeat $purple2;
            background-size: contain;
          }
        }

        &:focus-visible,
        &:hover {
          & + .item-wrap {
            &::before {
              background-color: $purple;
            }
          }
        }

        @include sm {
          &:hover {
            & + .item-wrap {
              &::before {
                background-color: $purple2;
              }
            }
          }
        }
      }

      &:focus-visible {
        & + .item-wrap {
          &::before {
            border-color: $gray60;
            background-color: $gray40;
          }
        }
      }
    }

    &.half {
      .item-wrap {
        &::before {
          border-color: $purple2;
          background: url("#{$publicPath}/assets/images/ico_checking.svg") 50%
            50% no-repeat $purple2;
        }
      }

      .input {
        &:focus-visible,
        &:hover {
          & + .item-wrap {
            &::before {
              background-color: $purple;
            }
          }
        }
        @include sm {
          &:hover {
            & + .item-wrap {
              &::before {
                background-color: $purple2;
              }
            }
          }
        }
      }
    }

    .item-wrap {
      display: inline-flex;
      height: 20px;
      align-items: center;

      .item-label {
        @include ellipsis(1);
        @include body-2;
        margin-left: 7px;
        color: $gray100;
      }

      &::before {
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid $gray50;
        border-radius: 2px;
        flex-shrink: 0;
        content: "";
      }

      &.left {
        flex-direction: row-reverse;

        .item-label {
          color: $gray100;

          margin: {
            right: 7px;
            left: 0;
          }
        }
      }
    }
  }
}
