/* header */

.ant-layout-header {
  padding: 0;
  z-index: 100;
}
.ant-layout-header.MAINNET {
  background: #ffffff;
}
.ant-layout-header.TESTNET {
  background: #000000;
}

.ant-layout-header .header-logo {
  width: 240px;
  padding: 0 22px;
}

.ant-layout-header .header-logo img {
  height: 32px;
  cursor: pointer;
}

.ant-layout-header .header-menu {
  width: 360px;
  height: 40px;
  padding: 0 16px;
}
.ant-layout-header .header-menu.MAINNET {
  border-left: #eeeef0 solid 2px;
}
.ant-layout-header .header-menu.TESTNET {
  border-left: #595959 solid 2px;
}

.header-gnb.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-layout-header .header-menu .ant-menu-horizontal {
  height: 40px;
  border: none;
  background-color: transparent;
}

.ant-layout-header .ant-row .header-menu .ant-menu-horizontal .ant-menu-item {
  padding: 0 12px;
  border: none;
}
.ant-layout-header
  .ant-row
  .header-menu
  .ant-menu-horizontal
  .ant-menu-item.MAINNET {
  color: rgba(0, 0, 0, 0.6);
}
.ant-layout-header
  .ant-row
  .header-menu
  .ant-menu-horizontal
  .ant-menu-item-selected.MAINNET {
  color: #261bc9;
}
.ant-layout-header
  .ant-row
  .header-menu
  .ant-menu-horizontal
  .ant-menu-item-active.MAINNET {
  color: #261bc9;
}
.ant-layout-header
  .ant-row
  .header-menu
  .ant-menu-horizontal
  .ant-menu-item.TESTNET {
  color: #595959;
}
.ant-layout-header
  .ant-row
  .header-menu
  .ant-menu-horizontal
  .ant-menu-item-selected.TESTNET {
  color: #ffffff;
}
.ant-layout-header
  .ant-row
  .header-menu
  .ant-menu-horizontal
  .ant-menu-item-active.TESTNET {
  color: #ffffff;
}

.ant-layout-header .ant-row .header-staking {
  height: 100%;
  padding: 0 16px;
}

.ant-layout-header .ant-row .header-staking div {
  padding: 0 12px 0 16px;
  height: 100%;
}

.ant-layout-header .ant-row .header-staking div p {
  line-height: 1.5;
  text-align: end;
}
.ant-layout-header .ant-row .header-staking div .staked.MAINNET {
  color: rgba(0, 0, 0, 0.87);
}
.ant-layout-header .ant-row .header-staking div .staked.TESTNET {
  color: #ffffff;
}
.ant-layout-header .ant-row .header-staking div .wemix.MAINNET {
  font-size: 12px;
}
.ant-layout-header .ant-row .header-staking div .wemix.TESTNET {
  font-size: 12px;
  color: #c2c4c4;
}

.ant-layout-header .ant-row .header-staking button {
  height: 40px;
}

/* staking modal */

.stakingModal .text-container {
  margin-top: 16px;
}

.stakingModal .text-container .staked {
  color: rgba(0, 0, 0, 0.87);
}

.stakingModal .ant-modal-body .ant-select {
  width: 100%;
  margin-bottom: 8px;
}

.ant-modal-footer .ant-btn-primary {
  background: #261bc9;
  border: 0;
}

/* error Modal */

.errorModal .ant-modal-body > p {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px;
}

.errorModal .ant-modal-body > div div p {
  margin: 1px 0 0 8px;
}

.errorModal .ant-modal-footer a {
  margin-right: 8px;
}

/* accessFail Modal */

.accessFail .ant-modal-body {
  height: 208px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.accessFail .ant-modal-body i {
  font-size: 68px;
  font-weight: 100;
  color: #fc0f1b;
}

.accessFail .ant-modal-body p {
  margin-top: 24px;
}

/* sub header */

.sub-header {
  background-color: #ffffff;
  position: relative;
  z-index: 90;
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
}

.sub-header .functionDiv.MAINNET {
  padding: 16px 16px 16px 16px;
}
.sub-header .functionDiv.TESTNET {
  padding: 16px 16px 16px 16px;
}

.sub-header .functionDiv .ant-input {
  height: 40px;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-top-width: 2px;
  border-right: none;
  border-bottom-width: 2px;
  border-left-width: 2px;
}

.sub-header .functionDiv button {
  height: 40px;
  font-size: 16px;
  border: none;
  color: #ffffff;
}
.sub-header .functionDiv button:disabled {
  color: #ffffff !important;
}
.sub-header .functionDiv.MAINNET button {
  background-color: #261bc9;
}
.sub-header .functionDiv.TESTNET button {
  background-color: #000000;
}

.sub-header .functionDiv > button {
  width: 208px;
  margin-left: 32px;
  justify-content: space-between;
}

/* affix */

.sub-menu {
  background-color: #ffffff;
  border-top: #eeeef0 2px solid;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.sub-menu .ant-menu-item {
  font-size: 16px;
  line-height: 50px;
}
.sub-menu .ant-menu-item-selected {
  color: #261bc9;
  border-color: #261bc9;
}
.sub-menu .ant-menu-item-active {
  color: #261bc9;
  border-color: #261bc9;
}

/* footer */

.ant-layout-footer {
  padding: 0;
  background-color: #1a1a1a;
}

.ant-layout-footer .ant-row {
  padding: 24px 16px;
}

.ant-layout-footer .ant-row div {
  justify-content: flex-end;
}

.ant-layout-footer .ant-row p {
  color: #ffffff;
}

.ant-layout-footer .ant-row div .ant-btn {
  height: 24px;
  margin-left: 16px;
}

.ant-layout-footer .ant-row div .ant-btn img {
  height: 24px;
}

/* Authority */

.contentDiv {
  padding-bottom: 64px;
}

.authorityComp {
  margin-top: 16px;
  padding: 0 16px;
}
.authorityComp:first-child {
  margin-top: 24px;
}

.authorityComp .authorityComp_contnet {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 16px;
  align-items: flex-start;
}

.authorityComp .authorityComp_contnet .img_container {
  width: 192px;
  height: 192px;
  margin: auto 16px auto auto;
  overflow: hidden;
}

.authorityComp .authorityComp_contnet .img_container img {
  width: 100%;
}

.authorityComp .authorityComp_contnet .text_container {
  padding-left: 24px;
  border-left: #eeeef0 solid 2px;
}

.authorityComp .authorityComp_contnet .text_container .title {
  margin: 8px 0 0 0;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.8);
}

.authorityComp .authorityComp_contnet .text_container .address {
  line-height: 20px;
}

.authorityComp .authorityComp_contnet .text_container .description {
  margin: 12px 0 0 0;
  height: 88px;
  min-height: 88px;
  overflow: hidden;
}

.authorityComp .authorityComp_contnet .text_container .link_container {
  margin: 12px 0 0 0;
  align-items: flex-end;
}

.authorityComp .authorityComp_contnet .text_container .more {
  line-height: 24px;
  cursor: pointer;
  color: #261bc9;
}

.authorityComp .authorityComp_contnet .text_container .SNSList {
  width: 24px;
  height: 24px;
  justify-content: flex-end;
}

.authorityComp .authorityComp_contnet .text_container .SNSList .snsGroup {
  margin: 0 0 0 8px;
}

.authorityComp
  .authorityComp_contnet
  .text_container
  .SNSList
  .snsGroup
  .fa-2x {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.6);
}
.authorityComp
  .authorityComp_contnet
  .text_container
  .SNSList
  .snsGroup
  .fa-2x:hover {
  color: #261bc9;
}

/* vote */

.ant-modal-body .changeDay {
  font-size: 46px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
}

.stateTitle {
  color: #000000;
  font-size: 30px;
  line-height: 38px;
  margin: 32px 0 24px 0;
  padding: 0 16px 0 16px;
}

.ballotDiv {
  background-color: #ffffff;
  margin: 0 16px 16px 16px;
  height: 124px;
  border-radius: 4px;
  overflow-y: hidden;
}
.ballotDiv:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.ballotDiv.InProgress {
  height: auto !important;
}

.ballotDiv .ballotInfoDiv {
  padding: 24px 16px 24px 16px;
  min-height: 124px;
}

.ballotDiv .ballotInfoDiv .infoLeft .topic {
  color: #261bc9;
  margin-bottom: 8px;
}

.ballotDiv .ballotInfoDiv .infoLeft .company {
  color: rgba(0, 0, 0, 0.87);
}

.ballotDiv .ballotInfoDiv .infoRight {
  width: 208px;
  align-items: flex-end;
  justify-content: space-between;
}

.ballotDiv .ballotInfoDiv .infoRight button {
  height: 28px;
  width: 28px;
  color: rgba(0, 0, 0, 0.87);
}

.ballotDiv .ballotInfoDiv .infoRight .status {
  color: #261bc9;
}
.ballotDiv.Changed .ballotInfoDiv .infoRight .status {
  color: green;
}

.ballotContentDiv .voteDiv {
  padding: 16px;
  border-top: #eeeef0 solid 2px;
  border-bottom: #eeeef0 solid 2px;
}

.ballotContentDiv .voteDiv .imageContent {
  height: 40px;
}

.ballotContentDiv .voteDiv .imageContent button {
  height: 40px;
  width: 64px;
  border: none;
}
.ballotContentDiv .voteDiv .imageContent #yesVotingBtn {
  background-color: #261bc9;
  color: white;
  margin-right: 16px;
}
.ballotContentDiv .voteDiv .imageContent #noVotingBtn {
  color: rgba(0, 0, 0, 0.6) !important;
  background-color: #fec3c2;
  margin-left: 16px;
}
.ballotContentDiv .voteDiv .imageContent button:disabled {
  background-color: #eeeef0 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.ballotContentDiv .voteDiv .imageContent .chart {
  padding: 4px 0 2px 0px;
}

.ballotContentDiv .voteDiv .imageContent .chart .number {
  margin-bottom: 4px;
  justify-content: space-between;
}

.ballotContentDiv .voteDiv .imageContent .chart .number {
  color: rgba(0, 0, 0, 0.87);
}

.ant-progress {
  line-height: 1 !important;
  font-size: 8px !important;
}

.ballotContentDiv
  .voteDiv
  .imageContent
  .chart
  .ant-progress-outer
  .ant-progress-inner {
  background-color: #fec7e0;
}

.ballotContentDiv
  .voteDiv
  .imageContent
  .chart
  .ant-progress-outer
  .ant-progress-inner
  .ant-progress-bg {
  background-color: #261bc9;
}

.ballotContentDiv .voteDiv .textContent {
  margin-top: 8px;
}

.ballotContentDiv .voteDiv .description {
  color: rgba(0, 0, 0, 0.6);
  min-height: 66px;
}

.ballotContentDiv .voteDiv .duration {
  width: 208px;
  margin-left: 96px;
}

.ballotContentDiv .voteDiv .duration > div > div {
  justify-content: space-between;
}

.ballotContentDiv .voteDiv .duration > div > div span:first-child {
  color: rgba(0, 0, 0, 0.87);
}

.ballotContentDiv .voteDiv .duration > div button {
  height: 30px;
  width: 208px;
  margin-top: 4px;
}

.ballotContentDiv .memoDiv {
  padding: 16px 16px 24px 16px;
}

.ballotContentDiv .memoDiv p:first-child {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
}

.ballotContentDiv .memoDiv p:last-child {
  color: rgba(0, 0, 0, 0.6);
  white-space: pre-wrap;
  word-break: break-word;
}

.ballotContentDiv .revokeDiv {
  padding: 24px 16px 24px 16px;
  border-top: #eeeef0 solid 2px;
  justify-content: flex-end;
}

.ballotContentDiv .revokeDiv button {
  height: 30px;
  width: 208px;
}

.moreDiv {
  margin-bottom: 16px;
}

.moreDiv button {
  height: 40px;
  width: 208px;
  border-radius: 4px;
  padding: 0 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

/* proposal */
.backBtnDiv {
  padding: 24px 16px 16px 16px;
}

.backBtnDiv button {
  height: 40px;
  width: 208px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.contentVotingDiv,
.contentRefDiv {
  margin: 0 16px 24px 16px;
  border-radius: 4px;
}
.contentVotingDiv {
  background-color: #ffffff;
}
.contentRefDiv {
  background-color: rgba(155, 174, 202, 0.2);
}
.contentRefDiv ol {
  margin-bottom: 0;
  padding-inline-start: 20px;
}
.contentRefDiv ol li,
.contentRefDiv p {
  color: #999999;
}
.contentRefDiv p {
  margin-bottom: 15px;
}
.contentRefDiv,
.contentVotingDiv .proposalHead {
  padding: 24px 16px;
  word-wrap: break-word;
}

.contentVotingDiv .proposalHead .title {
  align-items: baseline;
  margin-bottom: 8px;
}

.contentVotingDiv .proposalHead .title p:first-child {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 38px;
}

.contentVotingDiv .proposalHead .title p:last-child {
  color: #fc0f1b;
}

.contentVotingDiv .subtitle {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 16px;
  line-height: 1.5;
  white-space: nowrap;
}

.contentVotingDiv .required {
  color: #fc0f1b;
}

.contentVotingDiv .proposalHead .ant-select {
  margin-top: 8px;
  width: 100%;
}

.contentVotingDiv .proposalBody {
  border-top: #eeeef0 solid 2px;
  padding: 8px 16px 24px 16px;
}

.contentVotingDiv .ant-form-item {
  margin-top: 8px;
  margin-bottom: 0px;
}

.contentVotingDiv .ant-form-item .ant-input-search-button {
  width: 208px !important;
}

.contentVotingDiv .proposalBody .helpDescription {
  display: flex;
  background-color: #eeeef0;
  padding: 16px;
  margin-top: 12px;
}

.contentVotingDiv .proposalBody .helpDescription svg {
  height: 24px;
  width: 24px;
  padding: 2px;
}

.contentVotingDiv .proposalBody .helpDescription svg path {
  color: #261bc9;
}

.contentVotingDiv .proposalBody .helpDescription p {
  margin: 1px 0 0 8px;
}

.contentVotingDiv .proposalBody button[type="submit"] {
  border-radius: 4px;
  width: 208px;
  height: 40px;
}

.contentVotingDiv .proposalBody .submitDiv {
  margin-top: 24px;
  justify-content: flex-end;
}

.contentVotingDiv .proposalBody .divider > div:first-child {
  margin-right: 32px;
}

.contentVotingDiv .proposalBody .divider .flex-column .ant-form-item-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contentVotingDiv
  .proposalBody
  .divider
  .flex-column
  .ant-form-item-control
  .ant-form-item-children {
  display: flex;
}

.ant-form label {
  white-space: nowrap;
}

.loading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  width: 214px;
  padding-top: 70px;
}

.loading:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 206px;
  height: 60px;
  background-image: url("./loading.png");
  background-position: 0 0;
}

.loading-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  z-index: 80;
  background-color: rgba(38, 27, 201, 0.7);
}

.loading-i {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: fadeOut;
  animation-timing-function: linear;
  opacity: 0.2;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #fff;
}

.loading-i:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-i:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-i:nth-child(4) {
  animation-delay: 0.3s;
}

.loading-i:nth-child(5) {
  animation-delay: 0.4s;
}

.loading-i:nth-child(6) {
  animation-delay: 0.5s;
}
.hidden {
  display: none;
}

@keyframes fadeOut {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.2;
    transform: scale(0.3);
  }
}

.mini-loader {
  margin-top: 48px;
  font-size: 30px;
  line-height: 38px;
}

.accessFail .ant-modal-body {
  height: 208px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.accessFail .ant-modal-body i {
  font-size: 68px;
  color: #fc0f1b;
}

.accessFail .ant-modal-body p {
  margin-top: 24px;
}
.connect-btn.ant-btn-primary {
  margin-left: 12px;
  border: #ffffff solid 2px !important;
  background: #000000;
}
.connect-btn.ant-btn-primary:hover {
  background: #ffffff !important;
  color: #000000 !important;
}
.connect-btn.ant-btn-primary:focus {
  background: none;
  outline: none;
}

.wallet-list {
  display: flex;
  border: 1px solid #e8e8e8;
  align-items: center;
  justify-content: center;
  padding: 34px 0 34px 0;
  cursor: pointer;
}

.ant-btn.walletlist-cancel-btn {
  width: 181px;
  min-width: 180px;
  height: 60px;
  left: 0;
  top: 0;
  margin: auto;
}

.modal-disconnect-title {
  color: black;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}

/* .disconnect-modal.ant-modal-footer {
  border-top: none !important;
} */

.dis-connect-foot .ant-modal-footer {
  border-top: none !important;
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.ant-btn.discon-cancel-btn {
  flex: 1;
  height: 100%;
}
.ant-btn.discon-ok-btn {
  flex: 1;
  color: #ffffff;
  height: 100%;

  background: #1a1a1a;
}
