@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

.voting-list-block-wrap {
  position: relative;
  background-color: $gray30;
  width: 100%;
  border: 1px solid $gray40;
  transition: background-color 0.3s;
  & > a {
    display: block;
    padding: 24px 32px;
  }

  @include sm {
    & > a {
      padding: 20px 16px;
    }
  }
  &:hover {
    background-color: $gray40;
  }
  .wallet-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .ncp-info {
      display: flex;
      width: 100%;
      gap: 17px;
      align-items: center;
      & > span {
        display: inline-block;
        position: relative;
        z-index: 0;
        font-size: 16px;
        line-height: 1.5;
        & + span {
          &::before {
            @include content;
            position: absolute;
            width: 1px;
            background-color: $gray50;
            height: 9px;
            left: -9px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .ncp-name {
        span {
          color: $gray100;
          font-weight: 700;
          @include ellipsis(1);
        }
      }
      .ncp-address {
        color: $gray70;
        font-feature-settings: "ss20" on, "lnum" on, "tnum" on;
      }
    }
  }
  .list-title-wrap {
    margin-top: 12px;
    width: 100%;
    @include sm {
      margin-top: 18px;
    }
    .title {
      font-size: 18px;
      line-height: 1.2;
      color: $gray100;
      font-weight: 600;
      @include sm {
        margin-top: 26px;
        word-break: break-word;
      }
    }
  }
  .list-desc-wrap {
    margin-top: 2px;
    font-size: 16px;
    line-height: 1.5;
    color: $gray80;
    width: 80%;
    @include ellipsis(2);

    @include md {
      width: 100%;
    }
    @include sm {
      width: 100%;
      margin-top: 4px;
      @include ellipsis(3);
    }
  }
  .list-bottom-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    gap: 40px;
    @include sm {
      flex-direction: column;
      gap: 18px;
      margin-top: 16px;
    }

    .tx-state {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 500;

      @include sm {
        width: 100%;
      }

      &.tx-before {
        color: $gray70;
      }

      &.tx-complete {
        color: $positive;
      }
    }
  }

  & + .voting-list-block-wrap {
    margin-top: 12px;
  }
}
