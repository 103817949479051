// core 관련 색상, mixin (font) 정의
@import "../../assets/scss/core";

.platform-contents {
  @include core-mobile {
    border-top: 1px solid theme-rgba($theme-base-color, $line-subtler);
    margin-top: 40px;
    text-align: center;
  }

  .platform-inner-section {
    display: grid;
    padding: 16px;
    background-color: theme-color($theme-bg-color);
    grid-template-columns: repeat(auto-fill, minmax(100px, auto));
    row-gap: 10px;

    @include core-tablet {
      row-gap: 4px;
    }

    @include core-mobile {
      background-color: transparent;
      row-gap: 4px;
    }

    & + .platform-inner-section {
      border-top: 1px solid theme-rgba($theme-base-color, $line-subtler);
    }
  }

  // dark mode
  &.dark {
    @include theme-map($theme-dark);
  }

  // light mode
  &.light {
    @include theme-map($theme-light);
  }

  .platform-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 2px;
    border-radius: 8px;
    gap: 4px;

    img {
      width: 56px;
      height: 56px;

      @include core-mobile {
        width: 44px;
        height: 44px;
      }
    }

    @include core-mobile {
      padding: 8px 0;
    }
    @include core-transition-default(background-color);

    &:hover,
    &:focus-visible {
      background-color: theme-rgba($theme-base-color, $state-hover);
    }

    &:active {
      background-color: theme-rgba($theme-base-color, $state-pressed);
    }

    .platform-title {
      color: theme-color($core-text-subtle);
      text-align: center;

      @include core-font-compact-xs;
    }
  }
}
