@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

.wrap {
  position: relative;
  float: right;
  z-index: 10;
  display: inline-flex;
  margin-right: auto;
  margin-bottom: 12px;

  @include sm {
    margin-top: 14px;
  }

  .selected-btn {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid $gray40;
    border-radius: 6px;
    gap: 12px;

    @include transition-default(border-color);

    .title {
      display: flex;
      align-items: center;
      color: $gray90;
      gap: 4px;

      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }

    .arrow {
      @include transition-default(transform);
      font-feature-settings: "cv13" on, "calt" off;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.4;
      color: $gray90;
      font-family: "WEMIX Pretendard Symbol";
      transform-origin: center center;
    }

    &.active {
      .arrow {
        transform: rotate(-180deg);
      }
    }
  }

  .option-list {
    @include transition-default(opacity);

    position: absolute;
    top: calc(38px + 4px); // 버튼 높이 + 여백 4px
    right: 0;
    overflow: hidden;
    min-width: 160px;
    box-sizing: border-box;
    padding: 0 4px;
    border-radius: 8px;
    background-color: $gray20;
    box-shadow: 0 4px 20px 0 rgba(#000, 0.11), 0 0 5px 0 rgba(#000, 0.05);
    opacity: 0;
    transform: 100%;
    opacity: 0;
    transition-duration: 0.25; /* 250ms 디자인 가이드 정의; */

    &::before {
      @include content;

      position: absolute;
      right: 0;
      left: auto;
      z-index: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid $gray40;
      border-radius: 8px;
    }

    &.select-list-enter {
      opacity: 1;
    }

    &.select-list-leave {
      opacity: 0;
    }

    li {
      position: relative;
      z-index: 1;

      &:first-child {
        margin-top: 8px;
      }

      &:last-child {
        margin-bottom: 8px;
      }
    }

    .option-list-btn {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border-radius: 4px;
      gap: 4px;

      @include transition-default(background-color);

      &:hover {
        background-color: rgba(#fff, 0.07);
      }

      &:active,
      &:focus {
        background-color: rgba(#fff, 0.12);
      }

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
      }
      .label,
      .icon {
        color: $gray90;
      }

      .icon {
        font-feature-settings: "cv13" on, "calt" off;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.4;
        color: $gray90;
        font-family: "WEMIX Pretendard Symbol";
        transform-origin: center center;
      }
    }
  }
}
