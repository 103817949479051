@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
// footer 관련 색상, mixin (font) 정의
@import "../../assets/scss/core";

.wrap {
  position: relative;
  z-index: 10;
  display: inline-flex;
  margin-top: 14px;

  // dark mode
  &.dark {
    @include theme-map($theme-dark);
  }

  // light mode
  &.light {
    @include theme-map($theme-light);
  }

  @include core-tablet {
    margin-top: 0;
  }

  @include core-desktop {
    margin-top: 0;
  }

  &.icon {
    .language-btn {
      border: none;
      padding: 4px;
      border-radius: 100%;

      @include core-transition-default(background-color);

      &:hover {
        background-color: theme-rgba($theme-base-color, $line-subtlest);
      }

      &.active,
      &:active {
        background-color: theme-rgba($theme-base-color, $line-subtler);
      }
    }
    .svg-icon {
      width: 28px;
      height: 28px;
      img {
        width: 100%;
        height: 100%;
        // path {
        //   fill: theme-color($theme-base-color);
        // }
      }
    }
    .arrow {
      display: none;
    }

    .language-list {
      bottom: auto;
      top: calc(38px + 4px);
    }
  }
  .language-btn {
    display: inline-flex;
    align-items: center;
    padding: 8px;
    border: 1px solid theme-rgba($theme-base-color, $line-subtler);
    border-radius: 6px;
    gap: 6px;

    @include core-transition-default(border-color);

    .title {
      display: flex;
      align-items: center;
      color: theme-color($core-text-subtle);
      gap: 4px;
      .svg-icon {
        width: 20px;
        height: 20px;
        svg {
          width: 100%;
          height: 100%;
        }
      }

      @include core-font-compact-s;
    }

    .arrow {
      @include core-transition-default(transform);
      @include core-font-compact-xxs;

      color: theme-color($core-text-subtler);

      // TODO: 푸터 개선 작업 후 반영
      font-family: "WEMIX Pretendard Symbol";
      transform-origin: center center;
    }

    &:active {
      border-color: theme-rgba($theme-base-color, $line-strong);
    }

    &.active {
      .arrow {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      border-color: theme-rgba($theme-base-color, $line-normal);
    }
  }

  .language-list {
    @include core-transition-default(opacity);

    position: absolute;
    bottom: calc(38px + 4px); // 버튼 높이 + 여백 4px
    left: 0;
    overflow: hidden;
    min-width: 128px;
    box-sizing: border-box;
    padding: 0 6px;
    border-radius: 4px;
    background-color: theme-color($theme-bg-color);
    box-shadow: 0 4px 20px 0 rgba($core-scale-gray-0, 0.11),
      0 0 5px 0 rgba($core-scale-gray-0, 0.05);
    opacity: 0;
    transform: 100%;
    opacity: 0;
    transition-duration: 0.25; /* 250ms 디자인 가이드 정의; */

    &::before {
      @include core-content;

      position: absolute;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid theme-rgba($theme-base-color, $line-subtler);
      border-radius: 4px;
    }

    @include core-tablet {
      right: 0;
      left: auto;
    }

    @include core-desktop {
      right: 0;
      left: auto;
    }

    &.language-list-enter {
      opacity: 1;
    }

    &.language-list-leave {
      opacity: 0;
    }

    li {
      position: relative;
      z-index: 1;

      &:first-child {
        margin-top: 8px;
      }

      &:last-child {
        margin-bottom: 8px;
      }
    }

    .language-list-btn {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border-radius: 4px;
      gap: 4px;

      @include core-transition-default(background-color);

      &:hover {
        background-color: theme-rgba($theme-base-color, $line-subtlest);
      }

      &:active,
      &:focus {
        background-color: theme-rgba($theme-base-color, $line-subtler);
      }

      .label,
      .icon {
        color: theme-color($core-text-strong);

        @include core-font-compact-s;
      }

      .icon {
        // TODO: 푸터 개선 작업 후 반영
        font-family: "WEMIX Pretendard Symbol";
      }
    }
  }
}
