@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
// core 관련 색상, mixin (font) 정의
@import "../../assets/scss/core";

.footer {
  // dark mode
  &.dark {
    @include theme-map($theme-dark);
  }

  // light mode
  &.light {
    @include theme-map($theme-light);
  }

  padding: 24px 20px 40px;
  background-color: theme-color($core-bg-subtle);

  .footer-text-link,
  .footer-text-link span {
    color: theme-color($core-text-subtle);

    @include core-transition-default(color);

    &:hover {
      color: theme-color($core-text-normal);
    }

    &:active,
    &:focus {
      color: theme-color($core-text-strong);
    }
  }

  @include core-tablet {
    padding-top: 32px;
  }

  @include core-desktop {
    padding-top: 32px;
  }

  .footer-icon {
    display: block;
    width: 24px;
    height: 24px;
    opacity: 0.3;

    svg {
      path {
        fill: theme-color($theme-base-color) !important;
      }
    }

    @include core-transition-default(opacity);

    &:hover {
      opacity: 0.4;
    }
  }

  .footer-inner {
    @include core-desktop {
      // 각 플렛폼 max-width 적용 필요
      margin: 0 auto;
      padding: 0 20px;
    }

    .title-area {
      @include core-desktop {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .home {
        display: inline-flex;
        height: 40px;
        svg {
          path {
            fill: theme-color($theme-base-color) !important;
          }
        }
      }

      .navigation-wrap {
        // 상단 우측 (PC 기준) 바로가기 링크
        .quick-link-wrap {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          align-content: flex-start;
          align-items: flex-start;
          margin-top: 16px;
          gap: 8px 12px;

          a {
            display: flex;
            align-items: center;

            @include core-font-compact-s;
          }

          @include core-tablet {
            margin-top: 0;
            gap: 8px 20px;
          }

          @include core-desktop {
            margin-top: 0;
            gap: 8px 24px;
          }
        }

        @include core-tablet {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 6px;
        }

        @include core-desktop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0;
          gap: 24px;
        }
      }

      & + .sns-wrap {
        padding-top: 20px;
        margin-top: 20px;

        @include core-tablet {
          padding-top: 24px;
          margin-top: 12px;
        }

        @include core-desktop {
          margin-top: 10px;
        }

        &::before {
          border-color: theme-rgba($theme-base-color, $line-subtler);
        }
      }
    }

    .sns-wrap {
      position: relative;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      padding-top: 29.5px; // 24px 여백 + 5.5px
      gap: 8px;

      &::before {
        @include core-content;

        width: 100%;
        border-top: 1px solid theme-rgba($theme-base-color, $line-subtler);
      }

      @include core-mobile-fold {
        justify-content: start;
      }

      @include core-tablet {
        justify-content: start;
        padding-top: 31px; // 24px 여백 + 7px
        padding-bottom: 7px;
      }

      @include core-desktop {
        justify-content: start;
        padding-top: 31px; // 24px 여백 + 7px
        padding-bottom: 7px;
      }

      .sns-link {
        order: 3;

        a {
          display: flex;

          &:active,
          a:focus {
            .footer-icon {
              opacity: 0.5;
            }
          }
        }

        &.official-site {
          position: absolute;
          left: 0;
          order: 1;

          // wemix 로고 뒤로 줄 넘김 (280 대응)
          @include core-mobile-fold {
            position: relative;
            width: 100%;
          }

          @include core-tablet {
            position: relative;
            width: auto;
          }

          @include core-desktop {
            position: relative;
            width: auto;
          }
          .footer-icon {
            width: 93px;
            height: 38px;
          }
        }
      }

      &.official-site {
        &::after {
          display: none;
        }
      }

      // official site는 해당 코드 삭제 필요
      &::after {
        @include core-content;

        position: relative;
        display: flex;
        width: 93px;
        height: 38px;
        order: 2;

        // wemix 로고 뒤로 줄 넘김 (280 대응)
        @include core-mobile-fold {
          display: none;
        }

        @include core-tablet {
          display: block;
          width: 0;
        }

        @include core-desktop {
          display: block;
          width: 0;
        }
      }
    }
  }

  .util-wrap {
    position: relative;

    @include core-tablet {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 8px;
    }

    @include core-desktop {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 8px;
    }

    .terms-link-wrap {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: center;
      margin-top: 12px;
      gap: 4px 16px;

      @include core-tablet {
        max-width: 50%;
        margin-top: 0;
      }

      @include core-desktop {
        margin-top: 0;
      }

      .terms-link {
        a {
          display: flex;
          align-items: center;

          span {
            @include core-font-compact-xxs;
          }
        }
      }
    }
  }

  .copyright-wrap {
    position: relative;
    margin-top: 16px;

    &:only-child {
      @include core-tablet {
        position: absolute;
        right: 0;
        transform: translateY(-100%);
      }

      @include core-desktop {
        position: absolute;
        right: 0;
        transform: translateY(-100%);
      }
    }

    @include core-tablet {
      margin-top: 0;
    }

    @include core-desktop {
      margin-top: 0;
    }

    .badge-wrap {
      display: inline-flex;
      gap: 8px;

      svg {
        rect {
          fill: theme-color($theme-base-color);
          fill-opacity: 0.05;
        }

        path {
          fill: theme-color($theme-base-color);
          fill-opacity: 0.4;
        }

        :global {
          .transparent {
            fill: theme-color($theme-bg-color);
            fill-opacity: 0.75;
          }
        }
      }

      @include core-tablet {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(calc(-100% - 8px));
      }

      @include core-desktop {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(calc(-100% - 8px));
      }
    }

    .copyright {
      margin-top: 12px;
      color: theme-color($core-text-subtler);
      white-space: nowrap;

      @include core-font-compact-xxs;

      @include core-tablet {
        margin-top: 0;
      }

      @include core-desktop {
        margin-top: 0;
      }
    }
  }
}
