@import "./variables";
@import "./mixin";

.editor-wrap {
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 13px;
  }
  h6 {
    font-size: 10px;
  }
  u {
    text-decoration: underline;
  }
  a {
    text-decoration: underline !important;
    color: $primary;
  }
}
